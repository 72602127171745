import './index.css'

import { FC } from 'react'
import { Link } from 'react-router-dom'

type BreadcrumbItem = {
  label: string
  path: string | null
}

type BreadcrumbsProps = {
  items: BreadcrumbItem[]
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <nav className="breadcrumbs">
      <ul className="breadcrumbs-list">
        {items.map((item, index: number) => (
          <li
            className="breadcrumb-item"
            key={item.label}
          >
            {item.path ? (
              <Link
                to={item.path}
                className="breadcrumb-link"
              >
                {item.label}
              </Link>
            ) : (
              <span className="current-link">{item.label}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}
