import './index.css'

import { FC } from 'react'
import Avatar from '@mui/material/Avatar'

import { CommonRating, Box } from '@shared/components'
import { TCarReviews } from '@api/types'

const MIN_REVIEWS_COUNT = 3

type TReviewsProps = {
  withButton?: boolean
  reviews: TCarReviews
}

export const Reviews: FC<TReviewsProps> = ({ reviews, withButton = false }) => {
  return (
    <>
      <h4 className="reviews-title">
        Reviews&nbsp;
        <span className="customers-count">({reviews?.count} customers)</span>
      </h4>
      {reviews?.data?.length ? (
        reviews.data.map((review, index) => (
          <div key={index}>
            <div className="review-info">
              <Avatar
                alt="Avatar"
                src={review.avatar}
                sx={{ width: 80, height: 80 }}
              />
              <div className="review-details">
                <Box>
                  <p className="reviewer-name">{review?.name}</p>
                  <CommonRating value={review?.rating} />
                </Box>
                <Box gap="16px">
                  <p className="trips-count">{review?.tripsCount}</p>
                  <p className="trips-count">Joined {review?.joinedDate}</p>
                </Box>
              </div>
            </div>
            <p className="review-description">{review?.reviewDescription}</p>
          </div>
        ))
      ) : (
        <p className="no-reviews">There are no reviews yet</p>
      )}
      {reviews?.data?.length > MIN_REVIEWS_COUNT && withButton && (
        <button className="show-more-btn">Show more</button>
      )}
    </>
  )
}
