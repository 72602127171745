import { Select, MenuItem } from '@mui/material'

export const CommonSelect = ({
  value,
  onChange,
  options,
  width = '250px',
  iconColor = 'white',
  menuBgColor = 'black',
  itemTextColor = 'white',
  hoverBgColor = 'rgba(255, 255, 255, 0.2)'
}) => {
  return (
    <>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        inputProps={{
          'aria-label': 'Without label'
        }}
        sx={{
          width,
          color: itemTextColor,
          '& .MuiSvgIcon-root': {
            color: iconColor
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none'
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              padding: '10px',
              backgroundColor: menuBgColor,
              '& .MuiMenuItem-root': {
                color: itemTextColor,
                '&:hover': {
                  backgroundColor: hoverBgColor
                }
              }
            }
          }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
