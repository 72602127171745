import './index.css'

import { ChangeEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Dayjs as TDayjs } from 'dayjs'
import { Breadcrumbs, Modal, Fade, Popover } from '@mui/material'
import cn from 'classnames'

import {
  Box, Button, CommonSelect, SelectPickupAndReturn
} from '@shared/components'
import { Filters as FilterIcon, SortBy } from '@shared/icons'
import { usePost } from '@hooks/useApi'
import { URLParamsFormatter, QueryVoccab } from '@helpers/queryFormatter'
import { apiV1 } from '@api/v1'
import { TSearchRequest } from '@api/types'
import { CAR_BREADCRUMBS } from '@helpers/routes'
import PickupReturnInfo from '@shared/components/SelectLocation/PickupReturnInfo'

import AvailableCars from './components/Catalogue/AvailableCars'
import { FiltersGroup } from './components/Catalogue/Filters/FiltersGroup'
import { SORT_OPTIONS, SortOptionValue } from './utils/sort'

export const CatalogContainer = () => {
  const [, setSearchParams] = useSearchParams()
  const params = URLParamsFormatter.getParams()

  const appliedFilters = URLParamsFormatter.getDynamicObjectParam(QueryVoccab.filters)
  const pickupDateTime: TDayjs = URLParamsFormatter.getTimeParam(QueryVoccab.pickUpTime)
  const returnDateTime: TDayjs = !params[QueryVoccab.dropOffTime]
    ? URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime).add(2, 'day')
    : URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime)

  const [isFilterUpdated, setIsFilterUpdate] = useState(false)
  const [selectedOption, setSelectedOption] = useState(params[QueryVoccab.sort] ?? SortOptionValue.Standard)
  const [isEditClicked, setIsEditClicked] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const search = usePost<TSearchRequest>({
    url: '/search',
    body: {
      pickupLocationId: Number(params[QueryVoccab.pickUpLocationId]),
      pickupLocationType: params[QueryVoccab.pickUpLocationType],
      returnLocationId: params[QueryVoccab.dropOffLocationId],
      returnLocationType: params[QueryVoccab.dropOffLocationType],
      pickupDate: pickupDateTime.format(),
      returnDate: returnDateTime.format(),
      filters: apiV1.formatToFilters(appliedFilters)
    },
    deps: [params[QueryVoccab.pickUpLocationId], params[QueryVoccab.dropOffLocationId], isFilterUpdated]
  })

  const sortData = (value: SortOptionValue) => {
    setSearchParams(URLParamsFormatter.formatParam(QueryVoccab.sort, value))
    setSelectedOption(value)
  }

  const handleIsSearchVisible = () => {
    setIsFilterUpdate(!isFilterUpdated)
    setIsEditClicked(false)
  }

  const sendAnalyticEvent = (
    fieldName: TFilters['type'],
    selectedValues: TDynamicFilterParams['selectedValues']
  ) => {
    Analytics.send('filter-select', {
      name: fieldName,
      selectedFilters: selectedValues
    })
  }

  const handleSelectSortOption = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as SortOptionValue
    if (value) {
      sendAnalyticEvent('sort', value)
      sortData(value)
    }
  }

  // @TODO: Refactor sort component totally!!!!!!
  const handleSelectSortOptionMobile = (value: SortOptionValue) => {
    if (value) {
      sendAnalyticEvent('sort', value)
      sortData(value)
    }
  }

  const handleFilterChange = () => setIsFilterUpdate(!isFilterUpdated)

  return (
    <article className="catalog-cnt">
      <div className="catalog-wrapper">
        <div className="breadcrumbs">
          <Breadcrumbs items={CAR_BREADCRUMBS} />
        </div>
        <PickupReturnInfo
          className="pickup-return-cnt"
          onClick={() => setIsEditClicked(true)}
        />
        <div className="catalog-pickup-wrapper">
          <SelectPickupAndReturn onSearch={handleIsSearchVisible} />
        </div>

        <div className="sort-block">
          {/* @TODO: Desktop version for some reason here🫨😕 mobile inside AvailableCars */}
          {search.data?.vehicles && search.data.vehicles.length > 0
            ? `${search.data.vehicles.length} available cars`
            : 'No available cars'}
          <div className="sort-section">
            <Box gap="16px">
              <div className="sort-by">Sort by</div>
              <div className="filter">
                <CommonSelect
                  value={selectedOption}
                  options={SORT_OPTIONS}
                  onChange={handleSelectSortOption}
                />
              </div>
            </Box>
          </div>
        </div>
        {/* Filters && Sorting icons block */}
        <section className="filters-select-cnt">
          <input
            type="checkbox"
            id="toggleFilters"
            className="hidden"
          />
          <label htmlFor="toggleFilters">
            <div className="mobile-filters-block">
              <FilterIcon />
              <div className="action">&nbsp;Filters</div>
            </div>
          </label>
          <div
            className={cn('mobile-sort-block', {
              '-dimmed': Boolean(anchorEl)
            })}
          >
            <Button
              className="sort-btn"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
            >
              Sort by&nbsp;&nbsp;&nbsp;
              <SortBy />
            </Button>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <ul className="mobile-sort-list">
                {SORT_OPTIONS.map((option) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <li
                    key={option.value}
                    value={option.value}
                    onClick={() => {
                      handleSelectSortOptionMobile(option.value)
                      setAnchorEl(null)
                    }}
                    tabIndex={0}
                    role="button"
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </Popover>
          </div>
        </section>
        {/* --------------------------------- */}
        <div className="catalog-main-content">
          <FiltersGroup onFilterChange={handleFilterChange} />
          <AvailableCars searchList={search} />
        </div>
      </div>
      {isEditClicked && (
        <Modal
          open={isEditClicked}
          onClose={() => setIsEditClicked(false)}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500
            }
          }}
        >
          <Fade in={isEditClicked}>
            <div className="select-location">
              <SelectPickupAndReturn onSearch={handleIsSearchVisible} />
            </div>
          </Fade>
        </Modal>
      )}
    </article>
  )
}
