import React, { memo } from 'react'

import { TFilters, TFilterTypes } from '@api/types'

import MultiSelectFilter from '../../MultiSelectFilter/MultiSelectFilter'
import RangeFilter from '../../RangeFilter/RangeFilter'
import SwitchFilter from '../../CustomSwitch/SwitchFilter'

import { TDynamicFilterParams } from './FiltersGroup'

type TDynamicField = {
  data: TFilters
  defaultValue: string[] | undefined
  handleSelected: (params: TDynamicFilterParams) => void
}

export const DynamicField: React.FC<TDynamicField> = memo(
  ({ data, defaultValue, handleSelected }) => {
    const componentsMap: Record<TFilterTypes, React.ElementType> = {
      multiple: MultiSelectFilter,
      range: RangeFilter,
      single: SwitchFilter
    }

    const Component = componentsMap[data.type]
    if (!Component) return null

    return React.createElement(Component, {
      label: data.name,
      filterType: data.type,
      name: data.groupCode,
      options: data.values,
      defaultValue,
      handleChange: handleSelected,
      isSearchable: data.isSearch
    })
  },
  (prevProps, nextProps) => prevProps.defaultValue === nextProps.defaultValue
)
