export const aboutUsDate = {
  block1: [
    {
      info: 'Delivery 24/7',
      about:
        'We offer luxury and Offers delivery to any location in Florida including places like Key West, Miami, Palm Beach, Naples, Orlando and many more! And delivery to airports: Miami International Airport, Homestead General Aviation Airport, Miami Executive Airporthigh-performance cars that emphasize your style and status. Choose the best for work, leisure or special occasions'
    },
    {
      info: 'Fair deposit',
      about: 'Focuses on making trips more accessible to guests by making the security deposit affordable.'
    }
  ],
  block2: [
    {
      info: 'Flexible schedule',
      about:
        'Understands that every guest is unique, therefore, we are always ready to work with you to accommodate your every need.'
    },
    {
      info: 'Exceptional service',
      about:
        'Has worked with over 1000 customers in the last two years and made sure to create an unforgettable experience for guests.'
    }
  ]
}
