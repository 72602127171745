import { Fade, Modal } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dismiss } from '@shared/icons'
import { Button } from '@shared/components'

import { img1, img2, img3, img4, img5, img6, img7, img8 } from '../assets'

type ComingSoonModalProps = {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
}

const ComingSoonModal: FC<ComingSoonModalProps> = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate('/')
    setIsModalOpen(false)
    window.scrollTo(0, 0)
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeAfterTransition
      slotProps={{
        backdrop: { timeout: 500 }
      }}
    >
      <Fade in={isModalOpen}>
        <section className="home-modal-cnt">
          <div className="home-modal-inner">
            <div className="home-modal-close">
              <Dismiss
                callback={() => setIsModalOpen(false)}
                width="32"
                height="32"
                color="#fff"
              />
            </div>
            <div className="home-modal-title">
              <h4>Soon you will rent and drive the whip in MIA which gives you all you want</h4>
            </div>
            <div className="home-modal-images">
              <div className="home-modal-row row-1">
                <img
                  src={img1}
                  alt="Representing luxury lifestyle"
                />
                <img
                  src={img2}
                  alt="Representing luxury lifestyle"
                />
              </div>
              <div className="home-modal-row row-2">
                <img
                  src={img3}
                  alt="Representing luxury lifestyle"
                />
                <img
                  src={img4}
                  alt="Representing luxury lifestyle"
                />
                <img
                  src={img5}
                  alt="Representing luxury lifestyle"
                />
              </div>
              <div className="home-modal-row row-3">
                <img
                  src={img6}
                  alt="Representing luxury lifestyle"
                />
                <img
                  src={img7}
                  alt="Representing luxury lifestyle"
                />
                <img
                  src={img8}
                  alt="Representing luxury lifestyle"
                />
              </div>
              <Button
                name="Back to home"
                backgroundColor="#fff"
                onClick={onButtonClick}
                color="#000"
              />
            </div>
          </div>
        </section>
      </Fade>
    </Modal>
  )
}

export default ComingSoonModal
