import './index.css'

import { FC } from 'react'
import cn from 'classnames'

import CurrencyFormatter from '@helpers/currencyFormatter'

import { Button } from '../Button/Button'
import { emailStatus } from '../../../pages/completeProfile/StepsContainer'

type StickyButtonProps = {
  name: string
  price: number | null
  onButtonClick: () => void
  emailVerificationStatus?: string
  onBackButtonClick?: () => void
  onDetailsClick?: () => void
  disabled?: boolean
  isNextDisabled?: boolean
  isLoading?: boolean
  isHidedPrice?: boolean
}

export const StickyButton: FC<StickyButtonProps> = ({
  name,
  price,
  onButtonClick,
  emailVerificationStatus = '',
  onDetailsClick,
  onBackButtonClick,
  disabled = false,
  isNextDisabled = false,
  isLoading = false,
  isHidedPrice = false
}) => {
  switch (emailVerificationStatus) {
    case emailStatus.readyToSend:
      return (
        <div className="price-cnt-fixed ready-to-send">
          <Button
            onClick={onBackButtonClick}
            type="secondary"
            name="Edit"
            disabled={disabled}
          />
          <Button
            onClick={onButtonClick}
            name="Next"
            disabled={isNextDisabled}
          />
        </div>
      )
    case emailStatus.verifiedSuccess:
      return (
        <div className="price-cnt-fixed verified-email">
          <Button
            onClick={onButtonClick}
            name="Send"
            disabled={disabled}
            isLoading={isLoading}
          />
        </div>
      )
    default:
      return (
        <div className="price-cnt-fixed">
          <div className="price-cnt-fixed-content">
            <>
              <p
                className={cn('styled-price', {
                  '-hided': isHidedPrice
                })}
              >
                {price || price === 0
                  ? CurrencyFormatter.format({
                      amount: price
                    })
                  : 'Calculating...'}
              </p>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                className="price-details"
                onClick={onDetailsClick}
                role="button"
                tabIndex={0}
                aria-label="Show price details"
              >
                Price details
              </span>
            </>
          </div>
          <Button
            onClick={onButtonClick}
            name={name}
            disabled={disabled}
            isLoading={isLoading}
          />
        </div>
      )
  }
}
