import './index.css'

import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { Fade, Modal } from '@mui/material'

import { Button } from '@shared/components'
import { Dismiss, UploadCloud } from '@shared/icons'

import { Dropzone } from '../Dropzone'
import { uploadContainerTitle, uploadContainerAbout } from '../../data'

type TUploadSectionProps = {
  title?: string
  subtitle?: string
  onCloseUploadModal: () => void
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void
  uploadedFile: File | null
  setUploadedFile: Dispatch<SetStateAction<File | null>>
  isUploadModalVisible: boolean
  setShowFileError?: Dispatch<boolean>
}

export const UploadSection: FC<TUploadSectionProps> = ({
  onCloseUploadModal,
  title,
  subtitle,
  handleFileChange,
  uploadedFile,
  setUploadedFile,
  isUploadModalVisible,
  setShowFileError
}) => {
  const handleUpload = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setShowFileError(false)
      setUploadedFile(acceptedFiles[0])
    }
  }

  return (
    <Modal
      open={isUploadModalVisible}
      onClose={onCloseUploadModal}
      closeAfterTransition
    >
      <Fade in={isUploadModalVisible}>
        <div className="upload-modal">
          <div className="upload-wrapper">
            <div className="upload-content">
              <div className="upload-close">
                <Dismiss
                  width="32"
                  height="32"
                  callback={onCloseUploadModal}
                />
              </div>
              <div className="upload-text">
                <div className="upload-title">{title}</div>
                <div className="upload-subtitle">{subtitle}</div>
              </div>
              <Dropzone onDrop={handleUpload}>
                <div className="upload-container">
                  <UploadCloud />
                  <div className="upload-container-text">
                    <div className="upload-container-title">{uploadContainerTitle}</div>
                    <div className="upload-container-about">{uploadContainerAbout}</div>
                  </div>
                </div>
              </Dropzone>
              {uploadedFile && <div className="file-item">{uploadedFile.name}</div>}
              {!uploadedFile ? (
                <div className="uploaded-file">
                  <label
                    className="styled-upload-button"
                    htmlFor="file"
                  >
                    Upload Files
                  </label>
                  <input
                    type="file"
                    id="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
              ) : (
                <Button
                  name="Save"
                  onClick={onCloseUploadModal}
                />
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
