import { INTERNAL_SERVER_ERROR, SERVICE_PAGE } from '@helpers/routes'
import { QuerySuffixVoccab, TParseValue, URLParamsFormatter } from '@helpers/queryFormatter'

import { sanitizeFields } from '../utils/object'

export type TFetchOptions = RequestInit & {
  signal?: AbortSignal
}

export type TFetchResult<TRequest> = {
  isLoading: boolean
  data: TRequest | null
  error: Error | null
  abort: () => void
}

export enum TAllowedMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export type TRequestParams = {
  path: string
  method: TAllowedMethods
  options?: TFetchOptions
  body?: unknown
}

class ApiService {
  private readonly baseUrl: string
  private abortController: AbortController | null = null

  public isLoading = false
  public data: unknown = null
  public error: Error | null = null

  constructor(baseUrl: string) {
    if (!baseUrl) {
      throw new TypeError('baseUrl is required to API requests')
    }
    this.baseUrl = baseUrl
  }

  private async request<TRequest>(
    path: string,
    method: TAllowedMethods,
    options?: TFetchOptions
  ): Promise<TRequest | null> {
    this.abortController = new AbortController()
    const signal = this.abortController.signal

    const url = `${this.baseUrl}${path}`
    const response = await fetch(url, {
      ...options,
      method,
      signal
    })

    this.handleErrors(response, method)

    return this.handleContentType<TRequest>(response, url)
  }

  private handleErrors(response: Response, method: string): void {
    if (response.status >= 400 && response.status < 500) {
      throw new Error(`Client error on ${method}: ${response.status} ${response.statusText}`)
    }
    if (response.status >= 500) {
      window.location.href = SERVICE_PAGE(INTERNAL_SERVER_ERROR)
      throw new Error(`Server error on ${method}: ${response.status} ${response.statusText}`)
    }
  }

  private async handleContentType<TContent>(response: Response, url: string): Promise<TContent | null> {
    const contentType = response.headers.get('Content-Type')
    if (contentType?.includes('application/json')) {
      return (await response.json()) as TContent
    } else if (contentType?.includes('text/html')) {
      const htmlContent = await response.text()
      console.error(`Content-Type HTML for response ${url}:`, htmlContent)
      return null
    } else {
      throw new Error('Unsupported content type')
    }
  }

  public async requestHandler<TRequest>(
    path: string,
    method: TAllowedMethods,
    body?: TRequest,
    options?: TFetchOptions
  ): Promise<TFetchResult<TRequest>> {
    this.resetState()

    try {
      this.data = (await this.request<TRequest>(path, method, {
        headers: {
          'Content-Type': 'application/json'
        },
        ...options,
        body: body ? JSON.stringify(sanitizeFields(body as Record<string, any>)) : undefined
      })) as TRequest
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Unknown error occurred')
    } finally {
      this.isLoading = false
    }

    return this.createFetchResult<TRequest>()
  }

  public async batchRequestHandler<TRequest>(
    batchParams: Array<{ path: string; method?: TAllowedMethods; body?: TRequest; options?: TFetchOptions }>
  ): Promise<TFetchResult<TRequest>> {
    this.resetState()

    try {
      this.data = (await Promise.all(
        batchParams.map(({ path, method = TAllowedMethods.GET, body, options }) =>
          this.requestHandler<TRequest>(path, method, body, options)
        )
      )) as TRequest[]
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Unknown error occurred')
    } finally {
      this.isLoading = false
    }

    return this.createFetchResult<TRequest>()
  }

  private resetState(): void {
    this.isLoading = true
    this.data = null
    this.error = null
  }

  private createFetchResult<TRequest>(): TFetchResult<TRequest> {
    const result = {
      isLoading: this.isLoading,
      data: this.data as TRequest | TRequest[] | null,
      error: this.error,
      abort: () => this.abortRequest()
    }

    this.resetState()
    return result
  }

  public formatToFilters(
    input: Record<string, TParseValue>
  ): Array<{ groupCode: string; selectedValues: TParseValue }> {
    const filters = Object.entries(input).map(([key, value]) => ({
      groupCode: key,
      selectedValues: value,
      type: URLParamsFormatter.getParam(
        URLParamsFormatter.formatDynamicObjectServiceField(key, QuerySuffixVoccab.filterType)
      )
    }))

    return filters
  }

  public abortRequest(): void {
    if (this.abortController) {
      this.abortController.abort()
      this.abortController = null
      console.info('Request aborted')
    } else {
      console.warn('No request to abort')
    }
  }
}

export default ApiService
