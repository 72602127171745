import { FC, ErrorInfo, useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import { captureException } from '@sentry/react'

import { HOME } from '@helpers/routes'
import { Button } from '@shared/components'

// @TODO: implement proper styling for this page
type TBrokenApp = {
  resetError: () => void
  errorMessage: unknown
  componentStack?: ErrorInfo['componentStack']
}

const BrokenApp: FC<TBrokenApp> = ({ resetError, errorMessage, componentStack }) => {
  const error = useRouteError() as Error
  const navigate = useNavigate()

  useEffect(() => {
    captureException(error)
  }, [error])

  return (
    <div>
      <h1>Ouch!</h1>
      <Button
        name="Go back home"
        type="secondary"
        onClick={() => {
          navigate(HOME)
          resetError()
        }}
      />
      {/* {componentStack}
      {errorMessage.toString()} */}
    </div>
  )
}

export default BrokenApp
