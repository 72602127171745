import { useEffect, useState } from 'react'
import { createBrowserRouter, Outlet, useLocation, ScrollRestoration, Navigate } from 'react-router-dom'
import { wrapCreateBrowserRouterV6, ErrorBoundary } from '@sentry/react'

import { Header, Footer } from '@shared/components'
import { ABOUT_US, CAR, CATALOG, HOME, LEARN_MORE, SERVICE } from '@helpers/routes'
import { Page404 } from '@servicePages/components'
import Analytics from '@analytics/analytics'
import CookieBanner from '@shared/components/CookiePreferences/CookieBanner'
import CookieModal from '@shared/components/CookiePreferences/CookieModal'
import BrokenApp from '@servicePages/components/BrokenApp/BrokenApp'

import { HomeContainer } from './pages/home/HomeContainer'
import { CatalogContainer } from './pages/catalog/CatalogContainer'
import { StepsContainer } from './pages/completeProfile/StepsContainer'
import { CarCardContainer } from './pages/carCard/CarCardContainer'
import { ServicesContainer } from './pages/servicePages/ServicesContainer'
import { AboutUs } from './pages/AboutUs/AboutUs'
import { LegalMatters } from './pages/TermAndConditions/LegalMatters'
import { Terms } from './pages/Terms'
import { Prohibited } from './pages/Prohibited'
import { Privacy } from './pages/Privacy'
import { Rental } from './pages/Rental'
import { LearnMore } from './pages/learnMore/LearnMore'
import { Cookie } from './pages/Cookie'

export const Layout = () => {
  const { pathname } = useLocation()
  const [isCookieModalOpen, setIsCookieModalOpen] = useState<boolean>(false)
  const [isCookieBannerOpen, setIsCookieBannerOpen] = useState<boolean>(!Analytics.getIsAccepted())

  const onMoreInformationClick = () => {
    setIsCookieBannerOpen(false)
    setIsCookieModalOpen(true)
  }

  const onAcceptCookiesClick = () => {
    setIsCookieModalOpen(false)
    setIsCookieBannerOpen(false)
    localStorage.setItem('a', 'true')
  }

  const onRejectCookiesClick = () => {
    setIsCookieModalOpen(false)
    setIsCookieBannerOpen(false)
  }

  useEffect(() => {
    Analytics.send('pageview', {
      page: location.pathname
    })
  }, [location])

  return (
    <>
      <CookieModal
        isOpen={isCookieModalOpen}
        onRejectCookies={onRejectCookiesClick}
        onAcceptCookies={onAcceptCookiesClick}
      />
      <CookieBanner
        isOpen={isCookieBannerOpen}
        onMoreInformationClick={onMoreInformationClick}
        onAcceptCookies={onAcceptCookiesClick}
      />
      <ErrorBoundary
        fallback={({
          error, componentStack, resetError
        }) => (
          <BrokenApp
            resetError={resetError}
            errorMessage={error}
            componentStack={componentStack}
          />
        )}
      >
        <ScrollRestoration />
        {pathname !== '/' && <Header />}
        <Outlet />
        <Footer />
      </ErrorBoundary>
    </>
  )
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter)
export const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: HOME,
        element: <HomeContainer />
      },
      {
        path: CATALOG(),
        element: <CatalogContainer />
      },
      {
        path: `${SERVICE}/*`,
        element: <ServicesContainer />
      },
      {
        path: '/404',
        element: <Page404 />
      },
      {
        path: '/*',
        element: <Navigate to="/404" />
      },
      {
        path: CAR(':id'),
        element: <CarCardContainer />
      },
      {
        path: ABOUT_US,
        element: <AboutUs />
      },
      {
        path: LEARN_MORE,
        element: <LearnMore />
      },
      {
        id: 'legal',
        path: '/legal',
        Component: LegalMatters,
        children: [
          {
            path: 'terms',
            Component: Terms
          },
          {
            path: 'prohibited',
            Component: Prohibited
          },
          {
            path: 'privacy',
            Component: Privacy
          },
          {
            path: 'rental',
            Component: Rental
          },
          {
            path: 'cookie',
            Component: Cookie
          }
        ]
      },
      {
        path: `${CAR(':id')}/rentals/*`,
        Component: StepsContainer
      }
    ]
  }
])
