export const learnMoreDate = {
  title: 'At WHIPSS, we don’t just rent cars—we connect with the culture',
  about: 'We understand that for our community, rolling in a luxury whip is about more than getting from point A to B; it’s about making a statement. It’s about turning heads on the block, earning respect, and feeling unstoppable. Whether you’re cruising through Miami’s streets or pulling up to a big event, a WHIPSS rental makes sure you ride in style, confidence, and swagger.',
  subtitleDate: {
    subtitle: 'We know the vibes',
    description: 'Miami is bold, unapologetic, and about living life to the fullest. That’s why our fleet is designed to match your energy, whether you’re flexin’ with your crew, stuntin’ for a special occasion, or just chasing the dream. With WHIPSS, you’re not just driving; you’re leveling up.'
  },
  lastSection: {
    title: 'Ready to roll like a boss?',
    descriptionLink: 'Book your ride today',
    description: ' and experience what it feels like to be the top dog in the 305',
  }
}
