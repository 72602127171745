import './index.css'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'
import { RatingStar } from '@shared/icons'
import { formattedDateTime, pluralFormatter } from '@helpers/helpers'

import { useGlobalContext } from '../../../../GlobalContext'
import thankDate from '../../assets/thankDate.webp'
import thankLocation from '../../assets/thankLocation.webp'
import { useQuotaContext } from '../../hooks/useQuotaContext'

export const ThankCar = () => {
  const { carId } = useQuotaContext()
  const { getPotentialVehicle } = useGlobalContext()
  const vehicle = getPotentialVehicle(Number(carId))
  const pickUpLocationName = URLParamsFormatter.getParam(QueryVoccab.pickUpLocationName)
  const returnLocationName =
    URLParamsFormatter.getParam(QueryVoccab.dropOffLocationName) ?? pickUpLocationName

  return (
    <div className="thank-car-wrp">
      <div className="thank-car-img-cnt">
        <img
          className="thank-car-img"
          src={vehicle?.images[0][1]}
          alt="thankCarImg"
        />
      </div>

      <div className="thank-car-cnt">
        <div className="thank-car-name-cnt">
          <h3 className="thank-car-name">
            {vehicle?.details?.maker}&nbsp;{vehicle?.details?.model}
          </h3>
          <div className="thank-rating-wrp">
            <div className="thank-rating-cnt">
              <RatingStar
                width="24"
                height="24"
              />
              <div className="thank-rating-num">{vehicle?.details?.rating}</div>
            </div>
            <p className="thank-rating-trips">
              ({vehicle?.details?.reviews}&nbsp;
              {pluralFormatter.select(vehicle?.details?.reviews) === 'one' ? 'Review' : 'Reviews'})
            </p>
          </div>
        </div>

        <div className="thank-car-details-wrp">
          <div className="thank-details-cnt">
            <div className="thank-details-item">
              <img
                className="thank-detail-icon"
                src={thankDate}
                alt="thankDatePickupIcon"
              />
              <div className="thank-detail-text">
                <h5 className="thank-detail-info">Pickup Date</h5>
                <p className="thank-detail-about">
                  {formattedDateTime(URLParamsFormatter.getTimeParam(QueryVoccab.pickUpTime))}
                </p>
              </div>
            </div>

            <div className="thank-details-item">
              <img
                className="thank-detail-icon"
                src={thankDate}
                alt="thankDateReturnIcon"
              />
              <div className="thank-detail-text">
                <h5 className="thank-detail-info">Return Date</h5>
                <p className="thank-detail-about">
                  {formattedDateTime(URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime))}
                </p>
              </div>
            </div>
          </div>

          <div className="thank-details-cnt">
            <div className="thank-details-item">
              <img
                className="thank-detail-icon"
                src={thankLocation}
                alt="thankLocationPickupIcon"
              />
              <div className="thank-detail-text">
                <h5 className="thank-detail-info">Pickup Location</h5>
                <p className="thank-detail-about">{pickUpLocationName}</p>
              </div>
            </div>

            <div className="thank-details-item">
              <img
                className="thank-detail-icon"
                src={thankLocation}
                alt="thankLocationReturnIcon"
              />
              <div className="thank-detail-text">
                <h5 className="thank-detail-info">Return Location</h5>
                <p className="thank-detail-about">{returnLocationName}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
