import { useSearchParams } from 'react-router-dom'

import { TFilters, TFiltersResp, TFilterTypes } from '@api/types'
import { useGet } from '@hooks/useApi'
import { QuerySuffixVoccab, QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'
import Analytics from '@analytics/analytics'

import { DynamicField } from './DynamicField'

export type TDynamicFilterParams = {
  fieldName: TFilters['type']
  selectedValues: number[] | [boolean]
  type: TFilterTypes
}

type TFiltersGroup = {
  onFilterChange: () => void
}

export const FiltersGroup = ({ onFilterChange }: TFiltersGroup) => {
  const [, setSearchParams] = useSearchParams()
  const URLParams = URLParamsFormatter.getParams()

  const filterFields = useGet<TFiltersResp>({
    url: '/filters'
  })

  const sendAnalyticEvent = (
    fieldName: TFilters['type'],
    selectedValues: TDynamicFilterParams['selectedValues']
  ) => {
    Analytics.send('filter-select', {
      name: fieldName,
      selectedFilters: selectedValues
    })
  }

  const handleSelected = ({
    fieldName, selectedValues, type
  }: TDynamicFilterParams) => {
    const formattedParams = URLParamsFormatter.formatDynamicObjectParams({
      storeKey: QueryVoccab.filters,
      dynamicKey: fieldName,
      value: selectedValues,
      other: {
        // @INFO: build example -> car_brand_ft: multiple
        [URLParamsFormatter.formatDynamicObjectServiceField(fieldName, QuerySuffixVoccab.filterType)]: type
      }
    })

    setSearchParams(formattedParams)
    onFilterChange()
    sendAnalyticEvent(fieldName, selectedValues)
  }

  return (
    <div className="filters-wrapper">
      {filterFields?.data
        ?.sort((a, b) => a.sortIndex - b.sortIndex)
        .map((field) => (
          <DynamicField
            key={field.groupCode}
            defaultValue={URLParams[field.groupCode]}
            handleSelected={handleSelected}
            data={field}
          />
        ))}
    </div>
  )
}
