import { FC } from 'react'
import './index.css'

import { RatingStar } from '@shared/icons'

type CarRatingProps = {
  rating: string
  reviews: string
  width?: string
  height?: string
}

export const CarRating: FC<CarRatingProps> = ({ rating, reviews, width = '20', height = '20' }) => {
  return (
    <>
      <p className="rating">{rating}</p>
      <RatingStar
        width={width}
        height={height}
      />
      <p className="reviews">({reviews} reviews)</p>
    </>
  )
}
