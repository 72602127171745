import './index.css'

import { FC } from 'react'

import { TSearchValue } from '@api/types'
import { CAR } from '@helpers/routes'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import {
  AutomaticTransmission,
  Luggage,
  ManualTransmission,
  Passenger,
  Location,
  RatingStar
} from '@shared/icons'
import { pluralFormatter } from '@helpers/helpers'
import CurrencyFormatter from '@helpers/currencyFormatter'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'

type TCarCharacteristics = {
  car: TSearchValue
}

export const CarCharacteristics: FC<TCarCharacteristics> = ({ car }) => {
  const navPath = URLParamsFormatter.formatPathParams(CAR(car.id), {
    [QueryVoccab.pickUpLocationType]:
      URLParamsFormatter.getParam(QueryVoccab.pickUpLocationType) ?? car.location.type,
    [QueryVoccab.pickUpLocationId]:
      URLParamsFormatter.getParam(QueryVoccab.pickUpLocationId) ?? car.location.id,
    [QueryVoccab.pickUpLocationName]:
      URLParamsFormatter.getParam(QueryVoccab.pickUpLocationName) ?? car.location.name,
    [QueryVoccab.rentDays]: car.minRentDays
  })

  return (
    <div className="car-info">
      <div className="model">
        {car.brand}&nbsp;{car.model}
      </div>
      <div className="car-services">
        <div className="service-group">
          <div className="icon-wrapper">
            {car.transmission === 'manual' ? <ManualTransmission /> : <AutomaticTransmission />}
          </div>
          <div className="icon-wrapper">
            <Passenger />
            <span className="count">{car.passengers}</span>
          </div>
          <div className="icon-wrapper">
            <Luggage />
            <span className="count">{car.luggage}</span>
          </div>
        </div>
        <div className="catalog-rating-wrapper">
          <span className="catalog-rating">{car.rating}</span>
          <RatingStar className="catalog-rating-star" />
          <span className="catalog-reviews">{`(${car.reviewsCount} ${pluralFormatter.select(car.reviewsCount) === 'one' ? 'review' : 'reviews'})`}</span>
        </div>
      </div>
      <div className="speed-section">
        {car.acceleration && <span className="specification">{car.acceleration} sec / 0-60 mph</span>}
        {car.hp && <span className="specification">{car.hp} hp</span>}
        {car.mph && <span className="specification">{car.mph} mph</span>}
        {car.msrp && (
          <span className="specification">
            {CurrencyFormatter.format({
              amount: car.msrp
            })}
            &nbsp;msrp
          </span>
        )}
      </div>
      <div className="additional-info">
        <div className="location-info car-location">
          <Location />
          {car.location.name}
        </div>
        <div className="price">
          from&nbsp;
          {CurrencyFormatter.format({
            amount: car.rate
          })}
          &nbsp;&#47;&nbsp;day
        </div>
      </div>
      <div className="buttons-wrapper">
        <LinkButton
          to={navPath}
          name="Details"
        />
        <LinkButton
          to={navPath}
          type="primary"
          name="Rent now"
        />
      </div>
    </div>
  )
}
