import './index.css'

import { FC } from 'react'
import cn from 'classnames'
import { FieldError, UseFormRegister } from 'react-hook-form'

import { FormValues } from '../../../pages/completeProfile/types'

type FormInputProps = {
  id: 'email' | 'emailCode' | 'phone' | 'social'
  label: string
  placeholder: string
  type?: string
  defaultValue?: string
  register: UseFormRegister<FormValues>
  validation?: object
  error?: FieldError
  readOnly?: boolean
  className?: string
  note?: string
}

export const FormInput: FC<FormInputProps> = ({
  id,
  label,
  placeholder,
  type = 'text',
  defaultValue,
  register,
  validation,
  error,
  readOnly = false,
  note = ''
}) => {
  return (
    <div className="form-input-container">
      <label
        htmlFor={id}
        className="form-label"
      >
        {label}&nbsp;
        {note && <span className="form-note">({note})</span>}
      </label>
      <input
        id={id}
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        className={cn('form-input', { disabled: readOnly })}
        {...register(id, validation)}
        readOnly={readOnly}
      />
      {error && <span className="error-message">{error.message}</span>}
    </div>
  )
}
