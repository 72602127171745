import './index.css'

import { CheckedCheckbox, UncheckedCheckbox } from '../../icons'

type DifferentReturnLocationProps = {
  isReturnSelected: boolean
  callback: () => void
  padding?: string
}

export const DifferentReturnLocation = ({
  isReturnSelected,
  callback,
  padding
}: DifferentReturnLocationProps) => {
  return (
    <div
      className="wrapper-different-location"
      style={{
        padding: padding ?? '0'
      }}
      onClick={callback}
    >
      {isReturnSelected ? <CheckedCheckbox color="#7F7F7F" /> : <UncheckedCheckbox color="#E0E0E0" />}
      <div className="label">Different return location</div>
    </div>
  )
}
