import { FC, useEffect, useState } from 'react'

import { TPlan, TProtectionPlanResp } from '@api/types'
import { API_PROTECTION_PLANS_URL } from '@helpers/routes'
import { useGet } from '@hooks/useApi'

import { useQuotaContext } from '../../hooks/useQuotaContext'
import { StepProgress } from '../../components/StepsProgress/StepProgress'
import { ErrorMessage } from '../../components/ErrorMessage'
import { TProtectionPlanProps } from '../../types'
import { extrasOptionsStep, driversLicenseStep, content } from '../../data'
import { PROTECTION_PLAN } from '../../components/constants'
import { StepHeader } from '../../components/StepHeader'

import { CUSTOM_PROTECTION_PLAN, CustomProtectionPlanDetails } from './CustomProtectionPlanDetails'
import { ProtectionPlanCard } from './ProtectionPlanCard'
import { ProtectionPlanDetails } from './ProtectionPlanDetails'

export const ProtectionPlan: FC<TProtectionPlanProps> = ({
  setProtectionPlanFile,
  protectionPlanFile,
  setSelectedProtectionPlanId
}) => {
  const [protectionPlans, setProtectionPlans] = useState<TPlan[] | null>(null)
  const {
    isUploadModalVisible,
    setIsUploadModalVisible,
    calculatePriceError,
    selectedProtectionPlanId,
    carId
  } = useQuotaContext()
  const {
    data: protectionPlansData,
    isLoading: protectionPlansIsLoading,
    error: protectionPlansError
  } = useGet<TProtectionPlanResp>({
    url: `${API_PROTECTION_PLANS_URL}?carId=${carId}`
  })

  useEffect(() => {
    if (protectionPlansData) {
      const recommendedPlan = protectionPlansData.data.find((plan: TPlan) => plan.recommended)
      setProtectionPlans(protectionPlansData.data)

      if (selectedProtectionPlanId === null && recommendedPlan) {
        setSelectedProtectionPlanId(recommendedPlan.id)
      }
    }
  }, [protectionPlansData, selectedProtectionPlanId])

  const renderProtectionPlans = () => {
    if (protectionPlansIsLoading) return <div className="message">Loading...</div>
    if (protectionPlansError || calculatePriceError) {
      return <ErrorMessage message={protectionPlansError.message || calculatePriceError.message} />
    }

    return (
      <ul className="protection-plan-cards">
        {protectionPlans?.map((protectionCard) => (
          <li key={protectionCard.id}>
            <ProtectionPlanCard
              protectionCard={protectionCard}
              setSelectedProtectionPlanId={setSelectedProtectionPlanId}
              selectedProtectionPlanId={selectedProtectionPlanId}
            >
              <ProtectionPlanDetails protectionCard={protectionCard} />
            </ProtectionPlanCard>
          </li>
        ))}
        <li>
          <ProtectionPlanCard
            selectedProtectionPlanId={selectedProtectionPlanId}
            protectionCard={CUSTOM_PROTECTION_PLAN}
            setSelectedProtectionPlanId={setSelectedProtectionPlanId}
          >
            <CustomProtectionPlanDetails
              setProtectionPlanFile={setProtectionPlanFile}
              protectionPlanFile={protectionPlanFile}
              isUploadModalVisible={isUploadModalVisible}
              setIsUploadModalVisible={setIsUploadModalVisible}
            />
          </ProtectionPlanCard>
        </li>
      </ul>
    )
  }

  return (
    <>
      <main className="step-wrapper">
        <StepHeader
          title="Protection Plan"
          subtitle={content.protectionPlan.subtitle}
          stepNumber={PROTECTION_PLAN}
        />
        {renderProtectionPlans()}
      </main>
      <StepProgress name={extrasOptionsStep} />
      <StepProgress name={driversLicenseStep} />
    </>
  )
}
