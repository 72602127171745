import { Route, Routes } from 'react-router-dom'

import { INTERNAL_SERVER_ERROR } from '@helpers/routes'

import { Page500 } from './components/index'

export const ServicesContainer = () => {
  return (
    <Routes>
      <Route
        path={INTERNAL_SERVER_ERROR as string}
        element={<Page500 />}
      />
      {/* <Route
        path={CAR_IS_BOOKED as string}
        element={<CarIsBooked />}
      />
      <Route
        path={FIND_ANY_INFO as string} @TODO Fix me!!!!
        element={<FindAnyInfo />}
      /> */}
    </Routes>
  )
}
