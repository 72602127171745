import './index.css'

import { FC, useState } from 'react'
import { Fade, Modal } from '@mui/material'

import { ArrowBack, Close, Dismiss } from '@shared/icons'
import { Button, ResponsiveImage } from '@shared/components'
import { TVehicle } from '@api/types'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { QUOTA_STEPS, PROTECTION_PLAN_STEP_URL } from '@helpers/routes'
import { URLParamsFormatter } from '@helpers/queryFormatter'

import { CarRating } from '../CarRating/CarRating'
import { Reviews } from '../Reviews/Reviews'

type TCarGalleryProps = {
  vehicle: TVehicle | null
  onRentButtonClick: () => void
}

export const CarGallery: FC<TCarGalleryProps> = ({ vehicle, onRentButtonClick }) => {
  const [showModal, setShowModal] = useState(false)
  const imagesCount = vehicle?.images?.length

  const handleRentClick = () => {
    setShowModal(false)
    onRentButtonClick()
  }

  return (
    <>
      <div
        className="cover-wrapper"
        onClick={() => setShowModal(true)}
      >
        <ResponsiveImage
          className="cover"
          urls={vehicle?.images[0]}
          alt={`Interior view of ${vehicle?.details.maker}`}
        />
        {/* <div className="share-wrapper"><Share callback={() => {}} /></div> */}
      </div>

      <div className="thumbnails-container">
        {vehicle?.images.map((car, index) => {
          if (index >= 4) return null

          return (
            <div
              className={`thumbnails-wrapper ${index === 3 ? 'last-thumbnail' : ''}`}
              key={index}
              onClick={() => setShowModal(true)}
            >
              <ResponsiveImage
                className="thumbnail-img"
                urls={car}
                alt={`Interior view of ${vehicle?.details.maker}`}
              />
              <div className="images-count-overlay">+{imagesCount} images</div>
            </div>
          )
        })}
      </div>
      {showModal && vehicle?.images.length && (
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500
            }
          }}
        >
          <Fade in={showModal}>
            <article className="modal-cnt">
              <>
                <div className="close-action">
                  <h4 className="car-name">
                    {vehicle?.details.maker}&nbsp;{vehicle?.details.model}
                  </h4>
                  <Close callback={() => setShowModal(false)} />
                </div>
                <div className="car-images">
                  {vehicle?.images.map((car, index) => (
                    <div
                      key={index}
                      className={index === 0 ? 'firstImage' : 'otherImages'}
                    >
                      <ResponsiveImage
                        urls={car}
                        alt={`Interior view of ${vehicle?.details.maker}`}
                      />
                    </div>
                  ))}
                </div>
                <div className="gallery-button-wrapper">
                  <Button
                    type="secondary"
                    name="Rent now"
                    onClick={handleRentClick}
                  />
                </div>
              </>
              <div className="gallery-modal-cnt">
                <div className="gallery-modal-header">
                  <div className="gallery-header-back">
                    <div
                      className="gallery-arrow-wrapper"
                      onClick={() => setShowModal(false)}
                    >
                      <ArrowBack />
                    </div>
                    <h2 className="car-model-name">
                      {vehicle?.details.maker}&nbsp;{vehicle?.details.model}
                    </h2>
                    <div className="gallery-header-rating">
                      <CarRating
                        rating={vehicle?.details.rating}
                        reviews={vehicle?.details.reviews}
                        width="24"
                        height="24"
                      />
                    </div>
                  </div>
                  <div className="gallery-header-close">
                    <LinkButton
                      to={URLParamsFormatter.formatPathParams(
                        QUOTA_STEPS(PROTECTION_PLAN_STEP_URL, vehicle?.details.id)
                      )}
                      type="primary"
                      name="Rent now"
                    />
                    <div
                      className="gallery-arrow-wrapper"
                      onClick={() => setShowModal(false)}
                    >
                      <Dismiss
                        width="24"
                        height="24"
                        color="#BDBDBD"
                      />
                    </div>
                  </div>
                </div>

                <div className="gallery-modal-inner">
                  <div className="desktop-images">
                    {vehicle?.images.map((car, index) => (
                      <div key={index}>
                        <ResponsiveImage
                          urls={car}
                          alt={`Interior view of ${vehicle?.details.maker}`}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="reviews-block">
                    <Reviews reviews={vehicle?.reviews} />
                  </div>
                </div>
              </div>
            </article>
          </Fade>
        </Modal>
      )}
    </>
  )
}
