import './LinkButton.css'

import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import Analytics from '@analytics/analytics'

type LinkButtonProps = {
  to: string
  disabled?: boolean
  children?: ReactNode
  className?: string
  name?: string
  type?: 'primary' | 'secondary'
}

const LinkButton: FC<LinkButtonProps> = ({
  to,
  children,
  className,
  name,
  type = 'secondary',
  disabled = false
}) => {
  const handleOnClick = () => {
    Analytics.send('link-click', {
      name: name ?? 'no-name',
      to
    })
  }

  return (
    <Link
      to={to}
      viewTransition
      onClick={handleOnClick}
      className={cn('link-button', className, {
        '--primary': type === 'primary',
        '--secondary': type === 'secondary',
        '--disabled': disabled
      })}
    >
      {name ?? children}
    </Link>
  )
}

export default LinkButton
