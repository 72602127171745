export const SERVICE = '/service'
export const ABOUT_US = '/about-us'
export const LEARN_MORE = '/learn-more'

export const CATALOG = (path: string | number = '') => `/catalog${path}`
export const CAR = (path: string | number = '') => `/car/${path}`
export const QUOTA_STEPS = (stepId: string | number = '', carId: string | number = '') =>
  `/car/${carId}/rentals${stepId}`
export const SERVICE_PAGE = (path: string) => `/service${path}`

// HOME
export const HOME = '/'
export const HOME_FAQ_ANCHOR = '/#faq'
export const HOME_FLEET_ANCHOR = '/#fleet'

// CAR PAGE
export const API_VEHICLES = '/vehicles'

// Quota
export const PROTECTION_PLAN_STEP_URL = '/protection-plan'
export const DRIVER_STEP_URL = '/driver'
export const CONFIRM_STEP_URL = '/confirm'
export const EXTRAS_STEP_URL = '/extras'

// API
export const API_PROTECTION_PLANS_URL = '/protection_plans'
export const API_EXTRAS_URL = '/extras'
export const API_VERIFICATION_URL = '/verification'
export const API_VERIFICATION_CODE_URL = '/verification/code'
export const API_QUOTA_URL = '/quota'
export const API_CALCULATE_PRICE_URL = '/calculate_price'

//Service pages
export const INTERNAL_SERVER_ERROR = '/500'

// @TODO to refactor
// export const CAR_IS_BOOKED = '/car-is-booked'
// export const FIND_ANY_INFO = '/find-any-info'

export const CAR_BREADCRUMBS = [
  {
    label: 'home',
    path: HOME
  },
  {
    label: 'fleet',
    path: null
  }
]
