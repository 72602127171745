import './index.css'

import { FC } from 'react'

type TFreeDistanceProps = {
  distanceFreeIncluded: string
  distanceOverLimitCostMile: string
}

export const FreeDistance: FC<TFreeDistanceProps> = ({ distanceFreeIncluded, distanceOverLimitCostMile }) => {
  const roundedValue = parseFloat(distanceOverLimitCostMile).toFixed(2)

  return (
    <>
      <div className="distance-total">{distanceFreeIncluded} free mi total</div>
      <div className="extra-fee">${roundedValue}&#47;extra mi</div>
    </>
  )
}
