import { useState, useEffect, ChangeEvent, Dispatch, SetStateAction } from 'react'

type UseExtrasProps = {
  extraId: number
  setSelectedExtras: Dispatch<SetStateAction<Array<{ id: number; count: number }>>>
  selectedExtras: Array<{ id: number; count: number }>
}

export const useExtras = ({ extraId, setSelectedExtras, selectedExtras }: UseExtrasProps) => {
  const existingExtra = selectedExtras.find((extra) => extra.id === extraId)
  const [count, setCount] = useState(existingExtra?.count ?? 1)
  const [isChecked, setIsChecked] = useState(!!existingExtra)

  const adjustCount = (amount: number) => {
    setCount((currentCount) => currentCount + amount)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setIsChecked(checked)
    if (!checked) {
      setCount(1)
      setSelectedExtras((prev) => prev.filter((extra) => extra.id !== extraId))
    }
  }

  const handleExtrasChange = (id: number, quantity: number) => {
    setSelectedExtras((prev) => {
      if (count === 0) {
        return prev.filter((extra) => extra.id !== id)
      }

      const existing = prev.find((extra) => extra.id === id)
      if (existing) {
        return prev.map((extra) => (extra.id === id ? { ...extra, count: quantity } : extra))
      }
      return [...prev, { id, count: quantity }]
    })
  }

  useEffect(() => {
    if (isChecked) {
      handleExtrasChange(extraId, count)
    }
  }, [isChecked, count, extraId])

  return { count, isChecked, adjustCount, handleCheckboxChange }
}
