import './index.css'

import { Fade, Modal } from '@mui/material'
import { FC } from 'react'

import { Button } from '@shared/components'

type CookieBannerProps = {
  isOpen: boolean
  onMoreInformationClick: () => void
  onAcceptCookies: () => void
}
const CookieBanner: FC<CookieBannerProps> = ({ isOpen, onMoreInformationClick, onAcceptCookies }) => {
  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      slotProps={{
        backdrop: { timeout: 500 }
      }}
    >
      <Fade in={isOpen}>
        <section className="cookie-modal-cnt">
          <p className="cookie-modal-title">Cookies & Privacy</p>
          <div className="cookie-modal-inner">
            <p className="cookie-modal-description">
              This website uses cookies to ensure you get the best experience on our website.
            </p>
            <div className="cookie-modal-buttons">
              <Button
                name="More Information"
                onClick={onMoreInformationClick}
                type="linkSecondary"
              />
              <Button
                name="Accept All"
                onClick={onAcceptCookies}
                type="secondary"
              />
            </div>
          </div>
        </section>
      </Fade>
    </Modal>
  )
}

export default CookieBanner
