import { FC } from 'react'

import { CheckFilled } from '@shared/icons'
import './index.css'

type TStepProgressProps = {
  name: string
  completed?: boolean
}

export const StepProgress: FC<TStepProgressProps> = ({ name, completed }) => {
  return (
    <div className="step-progress-item">
      <div className={`step-progress-name ${completed ? 'completed' : ''}`}>{name}</div>
      {completed && <CheckFilled />}
    </div>
  )
}
