import { Dispatch, FC, SetStateAction } from 'react'

import './index.css'
import { TExtra } from '@api/types'

import { useExtras } from '../../hooks/useExtras'
import { useQuotaContext } from '../../hooks/useQuotaContext'

type TExtrasOptionItemProps = {
  extra: TExtra
  setSelectedExtras: Dispatch<SetStateAction<Array<{ id: number; count: number }>>>
}

export const ExtrasOptionItem: FC<TExtrasOptionItemProps> = ({ extra, setSelectedExtras }) => {
  const { selectedExtras } = useQuotaContext()
  const { count, isChecked, adjustCount, handleCheckboxChange } = useExtras({
    extraId: extra.id,
    setSelectedExtras,
    selectedExtras
  })

  return (
    <div className="extrasOption-item">
      <label className="extrasOption-title-wrapper">
        <input
          type="checkbox"
          className="extrasOption-checkbox"
          onChange={handleCheckboxChange}
          checked={isChecked}
        />
        <div className="extrasOption-title">{extra.title}</div>
      </label>
      <div className="extrasOption-about">{extra.description}</div>
      <div className="extrasOption-price">{extra.price}</div>
      {isChecked && extra.isMultiple && (
        <div className="extrasOption-quantity">
          <button
            type="button"
            className="quantity-btn"
            onClick={() => adjustCount(-1)}
            disabled={count <= 1}
          >
            -
          </button>
          <span className="quantity-value">{count}</span>
          <button
            type="button"
            className="quantity-btn"
            onClick={() => adjustCount(1)}
          >
            +
          </button>
        </div>
      )}
    </div>
  )
}
