import { TSearchValue } from '@api/types'

export enum SortOptionValue {
  LowToHigh = 'lowToHigh',
  HighToLow = 'highToLow',
  CarBrands = 'brand',
  NewToOld = 'newToOld',
  OldToNew = 'oldToNew',
  Standard = 'standard'
}

type SortOption = {
  value: SortOptionValue
  label: string
}

export const SORT_OPTIONS: SortOption[] = [
  {
    value: SortOptionValue.LowToHigh,
    label: 'Price low to high'
  },
  {
    value: SortOptionValue.HighToLow,
    label: 'Price high to low'
  },
  {
    value: SortOptionValue.CarBrands,
    label: 'Make A-Z'
  },
  {
    value: SortOptionValue.NewToOld,
    label: 'Year of release from new to old'
  },
  {
    value: SortOptionValue.OldToNew,
    label: 'Year of release from old to new'
  },
  {
    value: SortOptionValue.Standard,
    label: 'Standard sorting'
  }
]

export const sortCatalogue = (items: TSearchValue[], selectedOption: SortOptionValue): TSearchValue[] => {
  switch (selectedOption) {
    case SortOptionValue.LowToHigh:
      return items.sort((a, b) => a.rate - b.rate)
    case SortOptionValue.HighToLow:
      return items.sort((a, b) => b.rate - a.rate)
    case SortOptionValue.CarBrands:
      return items.sort((a, b) => `${a.model} ${a.brand}`.localeCompare(`${b.model} ${b.brand}`))
    case SortOptionValue.NewToOld:
      return items.sort((a, b) => b.productionYear - a.productionYear)
    case SortOptionValue.OldToNew:
      return items.sort((a, b) => a.productionYear - b.productionYear)
    case SortOptionValue.Standard:
      return items.sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
    default:
      return items
  }
}
