/* eslint-disable @typescript-eslint/prefer-includes */
import { TImageSized } from '../pages/types'

export const getImage = (urls: string[]): TImageSized => {
  const sources: TImageSized = {
    small: '',
    medium: '',
    large: ''
  }

  for (const url of urls) {
    if (url.indexOf('small') !== -1) {
      sources.small = url
    } else if (url.indexOf('medium') !== -1) {
      sources.medium = url
    } else if (url.indexOf('large') !== -1) {
      sources.large = url
    }
  }

  return sources
}
