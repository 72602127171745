import { FC, useState } from 'react'

import { TCarDescription } from '@api/types'

type VehicleDescriptionProps = {
  description: TCarDescription
}

export const VehicleDescription: FC<VehicleDescriptionProps> = ({ description }) => {
  const [showMore, setShowMore] = useState(false)
  const short = description?.short || ''
  const long = description?.long || ''
  const isEqual = short.length === long.length

  return (
    <>
      <h4 className="block-title">Description</h4>
      <p className="block-subtitle">{showMore ? long : short}</p>
      {!isEqual && (
        <button
          className="read-more-btn"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Read less' : 'Read more'}
        </button>
      )}
    </>
  )
}
