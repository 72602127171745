import './index.css'

import { FC } from 'react'
import { TCarFeature } from '@api/types'

type FeaturesProps = {
  features: TCarFeature[]
}

export const Features: FC<FeaturesProps> = ({ features }) => (
  <>
    <h5 className="features-title">Features</h5>
    <div className="features">
      {features?.map((feature, index) => (
        <div
          className="feature-item"
          key={index}
        >
          <img
            src={feature.iconUrl || ''}
            alt={`Feature ${feature?.value}`}
          />
          <p className="feature-description">{feature?.value}</p>
        </div>
      ))}
    </div>
  </>
)
