import { ReactNode, createContext, FC, useContext, useState } from 'react'

import { TVehicle, TVehicleResp } from '@api/types/vehicles'
import { TAllowedMethods, TFetchResult } from '@api/api'
import { apiV1 } from '@api/v1'
import { API_VEHICLES } from '@helpers/routes'

type TGlobalContextType = {
  getPotentialVehicle: (id: number) => TVehicle | null
  getVehicleById: (id: number, callback?: (value: TVehicle) => void) => Promise<void>
}

type GlobalProviderProps = {
  children: ReactNode
}

export const GlobalContext = createContext<TGlobalContextType | null>(null)

export const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
  const [vehiclesList, setVehiclesList] = useState<TVehicle[]>([])
  const [vehiclesIds, setVehiclesIds] = useState<number[]>([])
  const getPotentialVehicle = (id: number): TVehicle | null => {
    if (!id) return null
    const vehicle = vehiclesList.find((car) => car.details.id === Number(id))
    return vehicle ?? null
  }

  const getVehicleById = async (carId: number, callback: (value: TVehicle) => void = () => {}) => {
    if (carId && !vehiclesIds.includes(carId)) {
      try {
        const result: TFetchResult<TVehicleResp> = await apiV1.requestHandler<TVehicleResp>(
          `${API_VEHICLES}/${carId}`,
          TAllowedMethods.GET
        )
        if (!result.error && result.data?.vehicle) {
          callback(result.data.vehicle)
          setVehiclesList((prevList) => {
            const isVehicleAlreadyInList = prevList.some((car) => Number(car.details.id) === carId)
            return isVehicleAlreadyInList ? prevList : [...prevList, result.data.vehicle]
          })
          setVehiclesIds((prevIds) => {
            return prevIds.includes(carId) ? prevIds : [...prevIds, carId]
          })
        }
      } catch (err) {
        console.error('Error fetching vehicle:', err)
      }
    }
  }

  return (
    <GlobalContext.Provider value={{ getPotentialVehicle, getVehicleById }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider')
  }

  return context
}
