import './index.css'

import { Fade, Modal } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@shared/components'

type CookieModalProps = {
  isOpen: boolean
  onRejectCookies: () => void
  onAcceptCookies: () => void
}
const CookieModal: FC<CookieModalProps> = ({ isOpen, onRejectCookies, onAcceptCookies }) => {
  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      slotProps={{
        backdrop: { timeout: 500 }
      }}
    >
      <Fade in={isOpen}>
        <section className="cookie-modal-cnt">
          <h6 className="cookie-modal-title">Cookies on our website serve the following purposes:</h6>
          <p className="cookie-modal-description">
            Essential Navigation: Enable seamless browsing and efficient use of our services. These cookies
            are necessary and cannot be disabled.
          </p>
          <ul className="cookie-list">
            <li className="cookie-modal-description">
              Audience Analysis: Help us understand and analyze visitor activity on our site.
            </li>
            <li className="cookie-modal-description">
              Performance Optimization: Enhance website efficiency by monitoring and improving performance.
            </li>
            <li className="cookie-modal-description">
              Ad Personalization: Measure, refine, and tailor advertisements to your preferences.
            </li>
          </ul>
          <p className="cookie-modal-description">
            Some cookies are placed by our trusted partners. You can manage your cookie preferences and learn
            more about our practices anytime by visiting the&nbsp;
            <Link
              to="/legal/cookie"
              className="cookie-modal-link"
            >
              Cookies
            </Link>
            &nbsp;page via the website footer.
          </p>
          <div className="cookie-modal-buttons desktop">
            <Button
              name="Reject All"
              onClick={onRejectCookies}
              type="linkSecondary"
            />
            <Button
              name="Accept All"
              onClick={onAcceptCookies}
              type="secondary"
            />
          </div>
        </section>
      </Fade>
    </Modal>
  )
}

export default CookieModal
