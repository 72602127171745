import { Base } from './Base'
import { page500Date } from '../../data'
import './index.css'

export const Page500 = () => {
  return (
    <Base
      backgroundClass="page500-backImg"
      cntClass="page500-cnt"
      title={page500Date.title}
      subtitle={page500Date.subtitle}
      subtitleClass="page500-subtitle"
      about={page500Date.about}
      aboutClass="page500-about"
      button={page500Date.button}
    />
  )
}
