import './index.css'

import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import LinkButton from '@shared/components/LinkButton/LinkButton'
import dayjsExtended from '@helpers/dayjsExtended'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'
import { CATALOG, HOME } from '@helpers/routes'
import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@helpers/constants'
import { Logo } from '@shared/icons'

import { Menu } from '../Menu/Menu'

type HeaderProps = {
  setIsModalOpen: (value: boolean) => void
}

export const Header: FC<HeaderProps> = ({ setIsModalOpen }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleToggleMenu = () => setOpenMenu(!openMenu)

  return (
    <header className="header-wrapper">
      <div className="header-logo">
        <Link to={HOME}>
          <Logo />
        </Link>
      </div>
      <nav className="header-navigation">
        <div className="header-navigation-buttons">
          <LinkButton
            name="Cars available now"
            to={URLParamsFormatter.formatPathParams(CATALOG(), {
              [QueryVoccab.dropOffTime]: dayjsExtended.utc().add(AVAILABLE_NOW_MIN_RENT_DAYS, 'days').format()
            })}
          />
        </div>
        <Menu
          openMenu={openMenu}
          handleToggleMenu={handleToggleMenu}
        />
      </nav>
    </header>
  )
}
