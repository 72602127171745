import { celebrity1, celebrity2, celebrity3, celebrity4, celebrity5, celebrity6 } from '../../assets'

export const CelebritiesView = () => {
  return (
    <>
      <div className="celebrities-bg-top">
        <div className="celebrity-cnt">
          <img
            className="styled-image image-vertical"
            src={celebrity1}
            alt="Celebrity at night with car in the background"
          />
        </div>
        <div className="celebrity-cnt">
          <img
            className="styled-image"
            src={celebrity2}
            alt="Two celebrities sitting on a car with black and white background"
          />
        </div>
        <div className="celebrity-cnt">
          <img
            className="styled-image"
            src={celebrity3}
            alt="Celebrity sitting in a car with open doors"
          />
        </div>
      </div>
      <div className="celebrities-bg-bottom">
        <div className="celebrity-cnt">
          <img
            className="styled-image"
            src={celebrity4}
            alt="Celebrity pouring champagne with car in the background"
          />
        </div>
        <div className="celebrity-cnt">
          <img
            className="styled-image"
            src={celebrity5}
            alt="Celebrity in the city with car in the background"
          />
        </div>
        <div className="celebrity-cnt">
          <img
            className="styled-image image-vertical"
            src={celebrity6}
            alt="Celebrity with money in front of a car"
          />
        </div>
      </div>
    </>
  )
}
