import './index.css'

import { ChangeEvent, FC } from 'react'
import cn from 'classnames'

type TCustomSwitchProps = {
  label: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CustomSwitch: FC<TCustomSwitchProps> = ({ label, checked, onChange }) => (
  <div className="switch-item">
    <span className={cn('switch-label', { checked })}>{label}</span>
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        aria-label={label}
      />
      <span className="slider"></span>
    </label>
  </div>
)
