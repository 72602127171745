import { FC } from 'react'

import { STEPS_COUNT } from './constants'

type StepHeaderProps = {
  title: string
  subtitle: string
  stepNumber: number
}

export const StepHeader: FC<StepHeaderProps> = ({ title, subtitle, stepNumber }) => {
  return (
    <>
      <section className="step-content">
        <p className="step-main-title">Finalizing reservation</p>
        <p className="step-description">Getting the last few details together to get the final price</p>
      </section>
      <section className="step-title-wrapper">
        <div className="step-title-container">
          <div className="step-index">
            {stepNumber}/{STEPS_COUNT}
          </div>
          <div className="step-title">{title}</div>
        </div>
        <div className="step-subtitle">{subtitle}</div>
      </section>
    </>
  )
}
