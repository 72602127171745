import { FC } from 'react'

import { DRIVERS_LICENSE } from '../../components/constants'
import { StepHeader } from '../../components/StepHeader'
import { StepProgress } from '../../components/StepsProgress/StepProgress'
import { content, extrasOptionsStep, protectionPlanStep } from '../../data'
import { TClientDetailsProps } from '../../types'

import { ClientDetailsForm } from './ClientDetailsForm'

export const ClientDetails: FC<TClientDetailsProps> = ({
  sendQuota,
  handleUploadDriversLicenseFile,
  sendEmailVerificationRequest,
  setClientFormData
}) => {
  return (
    <>
      <main className="step-wrapper">
        <StepHeader
          title="Driver's License"
          subtitle={content.driversLicense.subtitle}
          stepNumber={DRIVERS_LICENSE}
        />
        <ClientDetailsForm
          sendQuota={sendQuota}
          handleUploadDriversLicenseFile={handleUploadDriversLicenseFile}
          sendEmailVerificationRequest={sendEmailVerificationRequest}
          setClientFormData={setClientFormData}
        />
        <div className="driversLicense-title-wrapper">
          <div className="driversLicense-subtitle">Why do I need to upload my driver's license?</div>
          <div className="driversLicense-description">{content.driversLicense.dlUploadReason}</div>
        </div>
      </main>
      <StepProgress
        name={protectionPlanStep}
        completed
      />
      <StepProgress
        name={extrasOptionsStep}
        completed
      />
    </>
  )
}
