import { FC } from 'react'
import { Dayjs as TDayjs } from 'dayjs'
import cn from 'classnames'

import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'
import { formattedDateTime } from '@helpers/helpers'
import { EditIcon } from '@shared/icons'

type PickupReturnInfoProps = {
  className?: string
  onClick?: () => void
}

const PickupReturnInfo: FC<PickupReturnInfoProps> = ({ className, onClick }) => {
  const params = URLParamsFormatter.getParams()
  const { pickUpLocation } = URLParamsFormatter.getQueryLocations()

  const pickUpDate: TDayjs | null = params[QueryVoccab.pickUpTime]
    ? URLParamsFormatter.getTimeParam(QueryVoccab.pickUpTime)
    : null
  const returnDate: TDayjs | null = params[QueryVoccab.dropOffTime]
    ? URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime)
    : null

  return (
    <button
      className={cn(className, 'pickup-summary')}
      onClick={onClick}
    >
      <p className="pickup-location">
        {URLParamsFormatter.getParam(QueryVoccab.pickUpLocationId)
          ? pickUpLocation?.label
          : 'Select pickup location'}
      </p>
      <p className="pickup-date">
        {pickUpDate ? formattedDateTime(pickUpDate) : 'Select pickup date'}
        &nbsp;&ndash;&nbsp;
        {returnDate ? formattedDateTime(returnDate) : 'Select return date'}
        {onClick && <EditIcon />}
      </p>
    </button>
  )
}

export default PickupReturnInfo
