import './index.css'

import { StepsImg } from '../../assets'
import { stepsList, stepsSubtitle } from '../../data'

const Steps = () => {
  return (
    <section className="steps-section">
      <div className="steps-title-container">
        <div>
          <h2 className="steps-title">How it works</h2>
          <p className="steps-subtitle">{stepsSubtitle}</p>
        </div>
        <div className="steps-img">
          <img
            src={StepsImg}
            alt="img.png"
          />
        </div>
      </div>
      <ul className="steps-container">
        {stepsList?.map((step) => (
          <li
            className="step"
            key={step.id}
          >
            <h6 className="steps-number">{step.id}</h6>
            <h6 className="steps-about">{step.description}</h6>
          </li>
        ))}
        {/* @FIXME: Add invisible button to test on staging and prod behavior of error tracing tool */}
        <button
          style={{
            display: 'none'
          }}
          onClick={() => {
            const data = NaN
            data.find((test) => test)
            throw new Error('Test Error Message 123')
          }}
        />
        <button
          style={{
            display: 'none'
          }}
          onClick={() => {
            throw new TypeError('Test Error Message 123')
          }}
        />
      </ul>
    </section>
  )
}

export default Steps
