import './index.css'

import { useEffect, useState } from 'react'
import { Dayjs as TDayjs } from 'dayjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Fade, Modal } from '@mui/material'

import { TCalculatePriceRes } from '@api/types'
import { usePost } from '@hooks/useApi'
import { CaretLeft, Location } from '@shared/icons'
import { Box, Breadcrumbs, SelectPickupAndReturn, StickyButton } from '@shared/components'
import PopularCars from '@servicePages/components/PopularCars/PopularCars'
import {
  API_CALCULATE_PRICE_URL,
  PROTECTION_PLAN_STEP_URL,
  QUOTA_STEPS,
  CATALOG,
  HOME
} from '@helpers/routes'
import { TFetchResult } from '@api/api'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'
import PickupReturnInfo from '@shared/components/SelectLocation/PickupReturnInfo'
import { TVehicle } from '@api/types/vehicles'

import { useGlobalContext } from '../../GlobalContext'

import { CarRating } from './components/CarRating/CarRating'
import { CarGallery } from './components/CarGallery/CarGallery'
import { VehicleDetails } from './components/VehicleDetails/VehicleDetails'
import { FreeDistance } from './components/FreeDistance/FreeDistance'
import { CarRates } from './components/CarRates/CarRates'
import { Features } from './components/Features/Features'
import { VehicleDescription } from './components/Description/VehicleDescription'
import { Extras } from './components/Extras/Extras'
import { DistanceBlock } from './components/DistanceBlock/DistanceBlock'
import { HostBlock } from './components/HostBlock/HostBlock'
import { Reviews } from './components/Reviews/Reviews'
import PriceDetails from './components/PriceDetails/PriceDetails'

export const CarCardContainer = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  if (!id) {
    navigate(URLParamsFormatter.formatPathParams(CATALOG()))
  }
  const [, setSearchParams] = useSearchParams()
  const params = URLParamsFormatter.getParams()

  const pickUpDate: TDayjs | null = params[QueryVoccab.pickUpTime]
    ? URLParamsFormatter.getTimeParam(QueryVoccab.pickUpTime)
    : null
  const returnDate: TDayjs | null = params[QueryVoccab.dropOffTime]
    ? URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime)
    : null

  const isSearchDisabled =
    !params[QueryVoccab.pickUpTime] ||
    !params[QueryVoccab.dropOffTime] ||
    !params[QueryVoccab.pickUpLocationId]

  const { getPotentialVehicle, getVehicleById } = useGlobalContext()
  const vehicle = getPotentialVehicle(Number(id))

  const [isEditClicked, setIsEditClicked] = useState(false)
  const [calculatedPrice, setCalculatedPrice] = useState<TCalculatePriceRes | null>(null)

  const handleNavigateNext = () => {
    if (isSearchDisabled) {
      setIsEditClicked(true)
    } else {
      navigate(URLParamsFormatter.formatPathParams(QUOTA_STEPS(PROTECTION_PLAN_STEP_URL, id)))
    }
  }

  const handleNavigateBack = () => {
    navigate(URLParamsFormatter.formatPathParams(CATALOG()))
  }

  const breadcrumbsItems = [
    {
      label: 'home',
      path: HOME
    },
    {
      label: 'fleet',
      path: CATALOG()
    },
    {
      label: `${vehicle?.details.maker} ${vehicle?.details.model}`,
      path: null
    }
  ]

  const calculatedPriceRes: TFetchResult<TCalculatePriceRes> = usePost({
    url: `${API_CALCULATE_PRICE_URL}?carId=${id}`,
    body: {
      pickUpLocation: {
        id: params[QueryVoccab.pickUpLocationId],
        type: params[QueryVoccab.pickUpLocationType]
      },
      returnLocation: {
        id: params[QueryVoccab.dropOffLocationId] ?? params[QueryVoccab.pickUpLocationId],
        type: params[QueryVoccab.dropOffLocationType] ?? params[QueryVoccab.pickUpLocationType]
      },
      pickUpDate: pickUpDate?.format(),
      returnDate: returnDate?.format(),
      extras: []
    },
    deps: [
      params[QueryVoccab.pickUpLocationId],
      params[QueryVoccab.dropOffLocationId],
      params[QueryVoccab.pickUpTime],
      params[QueryVoccab.dropOffTime]
    ]
  })

  const handleSetSearchParams = (car: TVehicle) => {
    if (!params[QueryVoccab.rentDays]) {
      setSearchParams(URLParamsFormatter.formatParam(QueryVoccab.rentDays, car.details.minRentDays))
    }
  }

  useEffect(() => {
    if (calculatedPriceRes.data) {
      setCalculatedPrice(calculatedPriceRes.data)
    }
  }, [calculatedPriceRes.data])

  useEffect(() => {
    void getVehicleById(Number(id), handleSetSearchParams)
  }, [id])

  return (
    <>
      <article className="car-card-wrapper">
        <div className="back-action">
          <CaretLeft callback={handleNavigateBack} />
          <h4 className="car-model-name">
            {vehicle?.details?.maker}&nbsp;{vehicle?.details?.model}
          </h4>
        </div>
        <div className="breadcrumbs">
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
        <section className="desktop-rating">
          <h2 className="car-model-name">
            {vehicle?.details?.maker}&nbsp;{vehicle?.details?.model}
          </h2>
          <Box
            alignItems="center"
            gap="2px"
          >
            <CarRating
              width="24"
              height="24"
              rating={vehicle?.details?.rating}
              reviews={vehicle?.details?.reviews}
            />
          </Box>
        </section>
        <PickupReturnInfo onClick={() => setIsEditClicked(true)} />
        <section className="car-gallery-cnt">
          <CarGallery
            vehicle={vehicle}
            onRentButtonClick={handleNavigateNext}
          />
        </section>
        <section className="car-details-mobile">
          <VehicleDetails
            details={vehicle?.details}
            size="20"
          />
          <Box
            alignItems="center"
            gap="2px"
          >
            <CarRating
              rating={vehicle?.details?.rating}
              reviews={vehicle?.details?.reviews}
            />
          </Box>
        </section>
        <section className="car-details-desktop">
          <VehicleDetails
            details={vehicle?.details}
            size="24"
            transmission="Transmission"
            seats="seats"
            places="places"
          />
          <div className="desktop-distance-cnt">
            <FreeDistance
              distanceFreeIncluded={vehicle?.details?.distanceFreeIncluded}
              distanceOverLimitCostMile={vehicle?.details?.distanceOverLimitCostMile}
            />
          </div>
        </section>
        <section className="location-info">
          <Location />
          {vehicle?.details?.location}
        </section>
        <section className="free-distance-cnt">
          <FreeDistance
            distanceFreeIncluded={vehicle?.details?.distanceFreeIncluded}
            distanceOverLimitCostMile={vehicle?.details?.distanceOverLimitCostMile}
          />
        </section>
        <section className="car-rates">
          <CarRates
            rateDay={vehicle?.details?.rates.day}
            rateWeek={vehicle?.details?.rates.week}
            rateMonth={vehicle?.details?.rates.month}
          />
        </section>
        <section className="features-section">
          <Features features={vehicle?.details?.features} />
        </section>
        <section className="description-block">
          <VehicleDescription description={vehicle?.details?.description} />
        </section>
        <section className="services-cnt">
          <Extras extras={vehicle?.details?.extras} />
        </section>
        <section className="distance-cnt">
          <DistanceBlock distanceFreeIncluded={vehicle?.details?.distanceFreeIncluded} />
        </section>
        <section className="host-cnt">
          <HostBlock host={vehicle?.host} />
        </section>
        <section className="reviews-cnt">
          <Reviews
            reviews={vehicle?.reviews}
            withButton
          />
        </section>
        <section className="suggested-whipss-cnt">
          <h4 className="block-title">Whipss you might like</h4>
          <PopularCars renderLimit={3} />
        </section>
        <StickyButton
          isHidedPrice={isSearchDisabled}
          onButtonClick={handleNavigateNext}
          onDetailsClick={() => setIsEditClicked(true)}
          name="Rent now"
          price={calculatedPrice?.total}
        />
      </article>
      {isEditClicked && (
        <Modal
          open={isEditClicked}
          onClose={() => setIsEditClicked(false)}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500
            }
          }}
        >
          <Fade in={isEditClicked}>
            <div className="select-location">
              <SelectPickupAndReturn
                searchButtonName="Rent now"
                isSearchDisabled={isSearchDisabled}
                onSearch={handleNavigateNext}
                afterChildren={<PriceDetails priceData={calculatedPrice} />}
                locations={vehicle?.locations}
              />
            </div>
          </Fade>
        </Modal>
      )}
    </>
  )
}
