import TagManager from 'react-gtm-module'

class AnalyticsInit {
  private gtmId: string | undefined

  constructor(gtmId: string | undefined) {
    this.gtmId = gtmId

    if (!this.gtmId) {
      console.error('Initialization failed: GTM ID is not provided.')
    }
  }

  private getInstance() {
    if (this.getIsAccepted()) {
      return TagManager
    } else {
      return {
        initialize: () => { },
        dataLayer: (args: any) => { }
      }
    }
  }

  public init() {
    if (!this.gtmId) {
      console.error('Initialization skipped: GTM ID is not defined.')
      return
    }
    const instance = this.getInstance()
    instance.initialize({
      gtmId: this.gtmId,
      auth: import.meta.env.VITE_GTM_AUTH as string | undefined,
      preview: import.meta.env.VITE_GTM_PREVIEW as string | undefined
    })
  }

  public send(event: string, metaData: Record<string, any>) {
    const analyticsArgs = {
      dataLayer: {
        event,
        ...metaData
      }
    }
    const instance = this.getInstance()
    instance.dataLayer(analyticsArgs)
  }

  public getIsAccepted() {
    // a -> allowAnalytics
    if (localStorage.getItem('a') === 'true') return true
    return false
  }
}

const Analytics: AnalyticsInit = new AnalyticsInit(import.meta.env.VITE_GTM_CODE as string | undefined)

export default Analytics
