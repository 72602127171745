import Rating from '@mui/material/Rating'
import { FC } from 'react'

type CommonRatingProps = {
  value: number
}

export const CommonRating: FC<CommonRatingProps> = ({ value }) => {
  return (
    <Rating
      precision={0.5}
      sx={{
        '& .MuiRating-iconFilled': {
          color: '#FFFFFF'
        },
        '& .MuiRating-iconEmpty': {
          color: '#7F7F7F'
        }
      }}
      name="read-only"
      value={value || 0}
      readOnly
    />
  )
}
