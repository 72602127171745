import './TermAndConditions/index.css'
import { Section, Subsection } from './TermAndConditions/types'

type TLegalContent = {
  data: Section[]
}

export const LegalContent = ({ data }: TLegalContent) => {
  const renderTitle = (section: Section) => {
    if (section.titleLarge) {
      return <h2 className="legal-title-large">{section.titleLarge}</h2>
    }

    if (section.titleLeft) {
      return <h2 className="legal-title-left">{section.titleLeft}</h2>
    }

    if (section.title) {
      return <h2 className="legal-title">{section.title}</h2>
    }

    return null
  }

  const renderSubtitle = (subsection: Subsection) => {
    if (subsection.subtitleCentre) {
      return <h3 className="legal-subtitle-centre">{subsection.subtitleCentre}</h3>
    }

    if (subsection.subtitle) {
      return <h3 className="legal-subtitle">{subsection.subtitle}</h3>
    }

    return null
  }

  const renderContent = (subsection : Subsection) => {
    if (subsection.contentDot) {
        return (
            <div className="legal-content-about-cnt">
                <span className="legal-content-about-dot">•</span>
                <p className="legal-content-about">{subsection.contentDot}</p>
            </div>
        )
    }

    if (subsection.contentBlock) {
        const [first, large, last] = subsection.contentBlock
        return (
            <p className="legal-content-about">
                {first}
                <span className="legal-content-about-large">{large}</span>
                {last}
            </p>
        )
    }

    if (subsection.contentDotBox) {
        return (
            <ul className="legal-content-dot-box">
                {subsection.contentDotBox.map((item, dotIndex) => (
                    <li key={dotIndex} className="legal-dot-item">
                        {item.contentDotSpan ? (
                            <>
                                <span className="legal-content-about-dot">•</span>
                                <p className="legal-content-about">
                                    <span className="legal-content-dot-span">
                                        {item.contentDotSpan}
                                    </span>
                                    {item.content}
                                </p>
                            </>
                        ) : (
                            <>
                                <span className="legal-content-about-dot">•</span>
                                <p className="legal-content-about">
                                    {item.contentDot}
                                    {item.link && (
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.link}
                                        </a>
                                    )}
                                </p>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        )
    }

    if (subsection.content) {
        return <p className="legal-content-about">{subsection.content}</p>
    }

    return null
  }

  return (
    <main className="legal-content">
      {data.map((section, index) => (
        <article key={index} className="legal-section">
          {renderTitle(section)}
          {section.subsections.map((sub, subIndex) => (
            <article key={subIndex} className="legal-subsection">
              {renderSubtitle(sub)}
              {renderContent(sub)}
            </article>
          ))}
        </article>
      ))}
    </main>
  )
}
