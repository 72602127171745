import './index.css'

import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { TExtra, TExtrasResp } from '@api/types'
import { API_EXTRAS_URL } from '@helpers/routes'
import { useGet } from '@hooks/useApi'

import { EXTRAS_OPTIONS } from '../../components/constants'
import { StepProgress } from '../../components/StepsProgress/StepProgress'
import { protectionPlanStep, driversLicenseStep, content } from '../../data'
import { ErrorMessage } from '../../components/ErrorMessage'
import { StepHeader } from '../../components/StepHeader'
import { useQuotaContext } from '../../hooks/useQuotaContext'

import { ExtrasOptionItem } from './ExtrasOptionsItem'

type TExtrasOptionsProps = {
  setSelectedExtras: Dispatch<SetStateAction<Array<{ id: number; count: number }>>>
}

export const ExtrasOptions: FC<TExtrasOptionsProps> = ({ setSelectedExtras }) => {
  const [extras, setExtras] = useState<TExtra[] | null>(null)
  const { carId } = useQuotaContext()
  const {
    data: extrasData,
    isLoading: extrasDataIsLoading,
    error: extrasDataError
  } = useGet<TExtrasResp>({
    url: `${API_EXTRAS_URL}?carId=${carId}`
  })

  useEffect(() => {
    if (extrasData) {
      setExtras(extrasData.data)
    }
  }, [extrasData])

  const renderExtras = () => {
    if (extrasDataIsLoading) return <div className="message">Loading...</div>
    if (extrasDataError) return <ErrorMessage message={extrasDataError.message} />

    return (
      <section className="extras-options-container">
        {extras?.map((extra: TExtra) => (
          <ExtrasOptionItem
            key={extra.id}
            extra={extra}
            setSelectedExtras={setSelectedExtras}
          />
        ))}
      </section>
    )
  }

  return (
    <>
      <main className="step-wrapper">
        <StepHeader
          title="Extras"
          subtitle={content.extrasOptions.subtitle}
          stepNumber={EXTRAS_OPTIONS}
        />
        {renderExtras()}
      </main>
      <StepProgress
        name={protectionPlanStep}
        completed
      />
      <StepProgress name={driversLicenseStep} />
    </>
  )
}
