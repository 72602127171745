export const fleetTitle = 'Our popular cars'
export const headerTitle = 'Car rental for Fam'
export const aboutText = {
  titleMain: 'Your friends are already shopping with us',
  subtitle:
    'Leading rental car company in South Florida with all makes and models. We understand your wants and won’t hold you back',
  titleSecondary: 'We get you',
  subtitleSecondary:
    'Our luxury performance cars give you the status you deserve. Show others what you are all about'
}
export const stepsSubtitle = 'We have an easy booking process that allows you to get the whip you want fast.'
export const stepsList = [
  {
    id: 1,
    description: 'Choose your whip'
  },
  {
    id: 2,
    description: 'Reserve online'
  },
  {
    id: 3,
    description: 'Get your whip'
  },
  {
    id: 4,
    description: 'Return or extend'
  }
]
export const faqList = [
  {
    id: 1,
    question: 'What are the requirements to rent?',
    answer: 'You need to have a valid license and be over 18 y/o'
  },
  {
    id: 2,
    question: 'What is your security deposit?',
    answer: 'Security deposit varies from $250 to $1000 depending on number of days and whip you choose.'
  },
  {
    id: 3,
    question: 'Can I extend the rental?',
    answer: 'You can always extend the rental as long as it not reserved already'
  },
  {
    id: 4,
    question: 'What are the mileage restrictions?',
    answer: 'You get 150 free miles per day, you can buy unlimited miles at checkout'
  },
  {
    id: 5,
    question: 'Do you require insurance on rentals?',
    answer: 'We only require insurance on exotic whipss'
  },
  {
    id: 6,
    question: 'Can I give the rental to my friend?',
    answer: 'Anyone driving needs to be added on the rental agreement to ensure the safety of renter'
  }
]

export const aboutMoreSubtitle =
  'Elevate your life with us,  where every detail is crafted to deliver a  experience of luxury, style, and sophistication. Our exclusive fleet of premium vehicles is designed for those who seek excellence without compromise, combining high-end performance with a bold, refined aesthetic.'
export const aboutMoreTitle = 'Feel the luxury of life'
