import { useState, useCallback, RefObject } from 'react'

function useDragHorizontal(ref: RefObject<HTMLElement>) {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollStart, setScrollStart] = useState(0)

  const onMouseDown = useCallback(
    (e) => {
      if (!ref.current) return
      setIsDragging(true)
      setStartX(e.pageX)
      setScrollStart(ref.current.scrollLeft)
    },
    [ref]
  )

  const onMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  const onMouseLeave = useCallback(() => {
    setIsDragging(false)
  }, [])

  const onMouseMove = useCallback(
    (e) => {
      if (!isDragging || !ref.current) return
      const moveDistance = e.pageX - startX
      ref.current.scrollLeft = scrollStart - moveDistance
    },
    [isDragging, startX, scrollStart, ref]
  )

  return {
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onMouseMove
  }
}

export default useDragHorizontal
