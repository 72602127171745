class CurrencyFormatter {
  private locale: string
  private defaultCurrency: string
  private defaultFractionDigits: number

  constructor(locale = 'en-US', defaultCurrency = 'USD', defaultFractionDigits = 0) {
    this.locale = locale
    this.defaultCurrency = defaultCurrency
    this.defaultFractionDigits = defaultFractionDigits
  }

  format(params: {
    amount: string | number
    locale?: string
    currency?: string
    fractionDigits?: number
  }): string {
    const {
      amount,
      locale = this.locale,
      currency = this.defaultCurrency,
      fractionDigits = this.defaultFractionDigits
    } = params

    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: fractionDigits
    }).format(numericAmount)
  }

  getCurrency(): string {
    return this.defaultCurrency
  }

  getCurrencySymbol(currency: string = this.defaultCurrency, locale: string = this.locale): string {
    return (
      new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
        .formatToParts(1)
        .find((part) => part.type === 'currency')?.value ?? ''
    )
  }
}

const CurrencyFormatterBuilder = new CurrencyFormatter('en-US', 'USD', 2)

export default CurrencyFormatterBuilder
