import './index.css'

import React, { FC, SyntheticEvent, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import { useForm } from 'react-hook-form'

import { Button, FormInput } from '@shared/components'
import { Delete } from '@shared/icons'
import { useFileUpload } from '@hooks/useFileUpload'

import { UploadSection } from '../../components/UploadSection/UploadSection'
import { FormValues, TClientDetailsProps } from '../../types'
import { emailStatus } from '../../StepsContainer'
import { useQuotaContext } from '../../hooks/useQuotaContext'

const emailValidation = (handleOnBlur: (field: string, value: string) => void) => ({
  required: 'Email is required',
  pattern: {
    value: /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
    message: 'Invalid email address'
  },
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim()
    handleOnBlur('email', trimmedValue)
  }
})

const emailCodeValidation = (handleOnBlur: (field: string, value: string) => void) => ({
  required: 'Email code is required',
  pattern: {
    value: /^[0-9]{6}$/,
    message: 'Email code must be exactly 6 digits'
  },
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleOnBlur('emailCode', event.target.value)
})

const phoneValidation = (handleOnBlur: (field: string, value: string) => void) => ({
  required: 'Phone number is required',
  pattern: {
    value: /^(?:\+1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    message: 'Invalid phone number format'
  },
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => handleOnBlur('phone', event.target.value)
})

export const ClientDetailsForm: FC<TClientDetailsProps> = ({
  sendQuota,
  handleUploadDriversLicenseFile,
  sendEmailVerificationRequest,
  setClientFormData
}) => {
  const [showFileError, setShowFileError] = useState(false)
  const { handleFileChange } = useFileUpload(handleUploadDriversLicenseFile)
  const {
    isUploadModalVisible,
    setIsUploadModalVisible,
    formRef,
    emailVerificationStatus,
    verificationError,
    clientFormData,
    driversLicenseFile
  } = useQuotaContext()
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    trigger
  } = useForm<FormValues>({ defaultValues: clientFormData })

  useImperativeHandle(formRef, () => ({
    submitForm: async () => {
      const isValid = await trigger()
      if (isValid) {
        void handleSubmit(sendQuota)()
      }
    }
  }))

  const showEmailCode =
    emailVerificationStatus === emailStatus.readyToSend ||
    emailVerificationStatus === emailStatus.verifiedSuccess
  const withError = showEmailCode && verificationError

  const handleRemoveDlFile = () => {
    if (showEmailCode) return
    setShowFileError(true)
    handleUploadDriversLicenseFile(null)
  }

  const onResendCodeClick = (e: SyntheticEvent<Element, Event>) => {
    e.preventDefault()
    void sendEmailVerificationRequest()
  }

  const email = watch('email')
  const emailCode = watch('emailCode')
  const phone = watch('phone')
  const social = watch('social')

  const handleOnBlur = async (field: string, value: string) => {
    const isValid = await trigger(field as 'email' | 'emailCode' | 'phone' | 'social')
    if (isValid) {
      setClientFormData((prevData: FormValues) => ({
        ...prevData,
        [field]: value
      }))
      setShowFileError(true)
    }
  }

  const renderEmailCodeVerification = () => {
    if (showEmailCode) {
      return (
        <>
          <div className="verify-email-wrapper">
            <div
              className={cn('verify-email-input', {
                withError: 'with-error'
              })}
            >
              <FormInput
                id="emailCode"
                label="Email-code*"
                placeholder="xxxxxx"
                defaultValue={emailCode}
                register={register}
                validation={emailCodeValidation(handleOnBlur)}
              />
            </div>
          </div>
          {withError && <span className="error-message">{verificationError}</span>}
          {errors.emailCode && <span className="error-message">{errors.emailCode.message}</span>}
          <div className="email-code-resend">
            <button
              className="send-email"
              onClick={(e) => onResendCodeClick(e)}
            >
              Send e-mail code again
            </button>
          </div>
        </>
      )
    }
    return null
  }

  return (
    <div className="form-wrapper">
      <form onSubmit={handleSubmit(sendQuota)}>
        <FormInput
          id="email"
          label="E-mail*"
          placeholder="Enter your e-mail"
          defaultValue={email}
          register={register}
          validation={emailValidation(handleOnBlur)}
          error={errors.email}
          readOnly={showEmailCode}
          note="We will send a verification code to your email"
        />
        {renderEmailCodeVerification()}
        <FormInput
          id="phone"
          label="Phone number*"
          placeholder="Enter your phone number"
          defaultValue={phone}
          register={register}
          validation={phoneValidation(handleOnBlur)}
          error={errors.phone}
          readOnly={showEmailCode}
        />
        <div className="form-upload">
          {driversLicenseFile ? (
            <>
              <label
                htmlFor="driversLicenseFile"
                className="styled-label"
              >
                Uploaded*
              </label>
              <div className="uploaded-file">
                <input
                  id="driversLicenseFile"
                  value={driversLicenseFile.name}
                  readOnly
                  onBlur={() => {
                    setShowFileError(true)
                  }}
                />
                <Delete callback={handleRemoveDlFile} />
              </div>
            </>
          ) : (
            <>
              <p className="styled-label">Driver's License Picture*</p>
              <Button
                onClick={() => setIsUploadModalVisible(true)}
                type="linkSecondary"
                name="Upload"
              />
            </>
          )}
          {!driversLicenseFile?.name && showFileError && (
            <span className="error-message">Driver's license is required</span>
          )}
        </div>
        <FormInput
          id="social"
          label="Instagram Handle"
          placeholder="@instagramaccount"
          defaultValue={social}
          register={register}
        />
      </form>
      <UploadSection
        title="Upload Driver's License"
        subtitle="Photo of the front side of the DL | Avoid blurs"
        setUploadedFile={handleUploadDriversLicenseFile}
        uploadedFile={driversLicenseFile}
        onCloseUploadModal={() => setIsUploadModalVisible(false)}
        handleFileChange={handleFileChange}
        isUploadModalVisible={isUploadModalVisible}
        setShowFileError={setShowFileError}
      />
    </div>
  )
}
