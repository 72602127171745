import './index.css'

import { FC } from 'react'

import { FindAnyCar } from '@servicePages/components'
import { TSearchResp } from '@api/types'
import { ResponsiveImage } from '@shared/components'
import { UseApiReturn } from '@hooks/useApi'
import { URLParamsFormatter, QueryVoccab } from '@helpers/queryFormatter'

import { CarCharacteristics } from '../CarCharacteristics/CarCharacteristics'
import { sortCatalogue, SortOptionValue } from '../../utils/sort'

type TAvailableCars = {
  searchList: UseApiReturn<TSearchResp>
}

const AvailableCars: FC<TAvailableCars> = ({ searchList }) => {
  const render = () => {
    if (searchList.isLoading) {
      return <div className="loading">Loading...</div>
    }

    if (searchList.data?.vehicles?.length) {
      return (
        <>
          <div className="mobile-catalog-title">Available cars</div>
          {sortCatalogue(
            searchList.data.vehicles,
            URLParamsFormatter.getParam(QueryVoccab.sort) as SortOptionValue
          ).map((vehicle) => (
            <div
              className="car-item"
              key={vehicle.id}
            >
              <div>
                <ResponsiveImage
                  urls={vehicle.src[0]}
                  alt={`Exterior view of ${vehicle.model}`}
                />
              </div>
              <CarCharacteristics car={vehicle} />
            </div>
          ))}
        </>
      )
    }

    return (
      <div className="mobile-catalog-title">
        <FindAnyCar />
      </div>
    )
  }

  return <div className="cars">{render()}</div>
}

export default AvailableCars
