import './index.css'

import { Link } from 'react-router-dom'

import { ABOUT_US, HOME, HOME_FAQ_ANCHOR, HOME_FLEET_ANCHOR } from '@helpers/routes'
import { Logo } from '@shared/icons'

const FOOTER_LINKS = [
  {
    href: ABOUT_US,
    label: 'About us'
  },
  {
    href: HOME_FLEET_ANCHOR,
    label: 'Fleet'
  },
  {
    href: HOME_FAQ_ANCHOR,
    label: 'FAQ'
  }
]

export const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="footer-content">
        <div className="footer-nav">
          <Link
            to={HOME}
            className="footer-logo"
          >
            <Logo />
          </Link>
          <div className="navigation-wrapper">
            <div className="navigation-title">Car rental in Miami</div>
            <ul>
              {FOOTER_LINKS.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.href}
                    className="link"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="copyright-wrapper">
          <div className="copyright-cnt">
            <span className="copyright">©2024 Whipss</span>
            <Link
              className="styled-link"
              to="/legal/privacy"
            >
              Privacy
            </Link>
            <Link
              className="styled-link"
              to="/legal/terms"
            >
              Terms
            </Link>
            <Link
              className="styled-link"
              to="/legal/prohibited/"
            >
              Prohibited Uses
            </Link>
            <Link
              className="styled-link"
              to="/legal/rental"
            >
              Rental Agreement
            </Link>
            <Link
              className="styled-link"
              to="/legal/cookie"
            >
              Cookies Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
