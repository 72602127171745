import './steps/ClientDetails/index.css'

import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'

import { usePost } from '@hooks/useApi'
import { apiV1, TAllowedMethods, TFetchResult } from '@api/v1'
import { TEmailVerificationCodeResp } from '@api/types/verification_code'
import { TQuotaResp } from '@api/types/quota'
import { TEmailVerificationResp } from '@api/types/verification'
import {
  API_CALCULATE_PRICE_URL,
  API_VERIFICATION_URL,
  API_VERIFICATION_CODE_URL,
  API_QUOTA_URL,
  QUOTA_STEPS,
  DRIVER_STEP_URL,
  PROTECTION_PLAN_STEP_URL,
  CONFIRM_STEP_URL,
  EXTRAS_STEP_URL,
  CAR,
  CATALOG
} from '@helpers/routes'
import { StickyButton } from '@shared/components'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'

import { useGlobalContext } from '../../GlobalContext'
import { PriceDetailsModal } from '../carCard/components/PriceDetails/PriceDetailsModal'

import { ProtectionPlan } from './steps/ProtectionPlan/ProtectionPlan'
import { ExtrasOptions } from './steps/ExtrasOptions/ExtrasOptions'
import { ThankYou } from './steps/ThankYou/ThankYou'
import { QuotaContext } from './context/QuotaContext'
import { FormValues } from './types'
import { ClientDetails } from './steps/ClientDetails/ClientDetails'

export type FormRef = {
  submitForm: () => void
}

const CUSTOM_PROTECTION_PLAN_ID = 0
const VERIFICATION_ERROR_MESSAGE = 'Something went wrong. Please try again later.'
const VERIFICATION_CODE_ERROR_MESSAGE =
  'The OTP code you entered is incorrect. Please check the code and try again.'
export const emailStatus = {
  default: '',
  readyToSend: 'readyToSend',
  verifiedSuccess: 'verifiedSuccess'
}

export const StepsContainer: FC = () => {
  const navigate = useNavigate()
  const formRef = useRef<FormRef | null>(null)
  const location = useLocation()
  const { id: carId } = useParams<{ id: string }>()
  if (!carId) {
    navigate(URLParamsFormatter.formatPathParams(CATALOG()))
  }

  const IS_PROTECTION_PLAN_STEP = location.pathname.includes(PROTECTION_PLAN_STEP_URL as string)
  const IS_EXTRAS_STEP = location.pathname.includes(EXTRAS_STEP_URL as string)
  const IS_DRIVER_STEP = location.pathname.includes(DRIVER_STEP_URL as string)
  const IS_CONFIRM_STEP = location.pathname.includes(CONFIRM_STEP_URL as string)

  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
  const [driversLicenseFile, setDriversLicenseFile] = useState<File | null>(null)
  const [protectionPlanFile, setProtectionPlanFile] = useState<File | null>(null)
  const [selectedProtectionPlanId, setSelectedProtectionPlanId] = useState<number | null>(null)
  const [selectedExtras, setSelectedExtras] = useState<Array<{ id: number; count: number }>>([])
  const [clientFormData, setClientFormData] = useState<FormValues>({
    email: '',
    emailCode: '',
    phone: '',
    social: ''
  })
  const [emailVerificationStatus, setEmailVerificationStatus] = useState<string>(emailStatus.default)
  const [verificationError, setVerificationError] = useState<string>('')
  const [quotaDetails, setQuotaDetails] = useState(null)
  const [isLoadingVerification, setIsLoadingVerification] = useState(false)
  const [token, setToken] = useState<string>('')
  const [isPriceDetailsModalOpen, setIsPriceDetailsModalOpen] = useState(false)
  const { getVehicleById } = useGlobalContext()
  const { setValue } = useForm<FormValues>({
    defaultValues: clientFormData
  })
  const verifyCodeData = {
    email: clientFormData.email,
    emailCode: clientFormData.emailCode,
    phone: clientFormData.phone
  }
  const verifyEmailData = {
    email: clientFormData.email
  }

  const resetQuotaContext = () => {
    setIsUploadModalVisible(false)
    setDriversLicenseFile(null)
    setProtectionPlanFile(null)
    setSelectedProtectionPlanId(null)
    setSelectedExtras([])
    setClientFormData({
      email: '',
      emailCode: '',
      phone: '',
      social: ''
    })
    setEmailVerificationStatus(emailStatus.default)
    setVerificationError('')
    setQuotaDetails(null)
    setIsLoadingVerification(false)
    setToken('')
  }

  useEffect(() => {
    return () => resetQuotaContext()
  }, [])

  useEffect(() => {
    if (!selectedProtectionPlanId) {
      goToNextStep(PROTECTION_PLAN_STEP_URL)

      if (IS_CONFIRM_STEP) {
        navigate(URLParamsFormatter.formatPathParams(CAR(carId)))
      }
    }
  }, [selectedProtectionPlanId, IS_CONFIRM_STEP])

  useEffect(() => {
    void getVehicleById(Number(carId))
  }, [carId])

  const locationsBody = {
    pickUpLocation: {
      id: URLParamsFormatter.getParam(QueryVoccab.pickUpLocationId),
      type: URLParamsFormatter.getParam(QueryVoccab.pickUpLocationType)
    },
    returnLocation: {
      id:
        URLParamsFormatter.getParam(QueryVoccab.dropOffLocationId) ??
        URLParamsFormatter.getParam(QueryVoccab.pickUpLocationId),
      type:
        URLParamsFormatter.getParam(QueryVoccab.dropOffLocationType) ??
        URLParamsFormatter.getParam(QueryVoccab.pickUpLocationType)
    }
  }

  const { data: calculatedPrice, error: calculatePriceError } = usePost({
    url: `${API_CALCULATE_PRICE_URL}?carId=${carId}`,
    body: {
      ...locationsBody,
      pickUpDate: URLParamsFormatter.getParam(QueryVoccab.pickUpTime),
      returnDate: URLParamsFormatter.getParam(QueryVoccab.dropOffTime),
      protectionPlan: selectedProtectionPlanId,
      extras: selectedExtras
    },
    deps: [selectedProtectionPlanId, selectedExtras]
  })

  const sendEmailVerificationRequest = async () => {
    setIsLoadingVerification(true)
    try {
      const result: TFetchResult<TEmailVerificationResp> = await apiV1.requestHandler<TEmailVerificationResp>(
        API_VERIFICATION_URL,
        TAllowedMethods.POST,
        verifyEmailData
      )
      if (!result.error) {
        setEmailVerificationStatus(emailStatus.readyToSend)
      } else {
        setVerificationError(VERIFICATION_ERROR_MESSAGE)
      }
    } catch {
      setVerificationError(VERIFICATION_ERROR_MESSAGE)
    } finally {
      setIsLoadingVerification(false)
    }
  }

  const sendEmailVerificationCodeRequest = async () => {
    setIsLoadingVerification(true)
    try {
      const result: TFetchResult<TEmailVerificationCodeResp> =
        await apiV1.requestHandler<TEmailVerificationCodeResp>(
          API_VERIFICATION_CODE_URL,
          TAllowedMethods.POST,
          verifyCodeData
        )
      if (!result.error && result.data.token) {
        setEmailVerificationStatus(emailStatus.verifiedSuccess)
        setToken(result.data.token)
        setVerificationError('')
      } else {
        setVerificationError(VERIFICATION_CODE_ERROR_MESSAGE)
      }
    } catch {
      setVerificationError(VERIFICATION_CODE_ERROR_MESSAGE)
    } finally {
      setIsLoadingVerification(false)
    }
  }

  const sendQuota = async (data: FormValues) => {
    setIsLoadingVerification(true)
    setClientFormData(data)

    try {
      const result: TFetchResult<TQuotaResp> = await apiV1.requestHandler<TQuotaResp>(
        API_QUOTA_URL,
        TAllowedMethods.POST,
        {
          carId: Number(carId),
          token,
          instagram: clientFormData?.social ? clientFormData?.social : null,
          locations: locationsBody,
          pickUpDate: URLParamsFormatter.getTimeParam(QueryVoccab.pickUpTime).format(),
          returnDate: URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime).format(),
          protectionPlan: {
            id: selectedProtectionPlanId,
            file: selectedProtectionPlanId === CUSTOM_PROTECTION_PLAN_ID ? protectionPlanFile?.data : null
          },
          extras: selectedExtras,
          driverLicence: driversLicenseFile?.data
        }
      )
      if (!result.error) {
        setQuotaDetails(result.data)
        navigate(URLParamsFormatter.formatPathParams(QUOTA_STEPS(CONFIRM_STEP_URL, carId)))
      }
    } catch {
      setVerificationError('Unexpected error occurred')
    } finally {
      setIsLoadingVerification(false)
    }
  }

  const handleUploadDriversLicenseFile = (fileData: File | null) => {
    setDriversLicenseFile(fileData)
  }

  const handleStickyButtonClick = () => {
    if (emailVerificationStatus === emailStatus.default && !clientFormData.emailCode) {
      void sendEmailVerificationRequest()
    } else if (emailVerificationStatus === emailStatus.readyToSend && clientFormData.emailCode) {
      void sendEmailVerificationCodeRequest()
    } else if (emailVerificationStatus === emailStatus.verifiedSuccess && token) {
      void formRef?.current?.submitForm()
    }
  }

  const onBackButtonClick = () => {
    setEmailVerificationStatus(emailStatus.default)
    setValue('emailCode', '')
  }

  const goToNextStep = (path: string) =>
    navigate(URLParamsFormatter.formatPathParams(QUOTA_STEPS(path, carId)))

  const getOnButtonClick = () => {
    if (location.pathname.includes(PROTECTION_PLAN_STEP_URL)) return goToNextStep(EXTRAS_STEP_URL)
    if (location.pathname.includes(EXTRAS_STEP_URL)) return goToNextStep(DRIVER_STEP_URL)
    return handleStickyButtonClick()
  }

  const getDisabled = () => {
    if (IS_EXTRAS_STEP) return false
    if (
      PROTECTION_PLAN_STEP_URL &&
      selectedProtectionPlanId === CUSTOM_PROTECTION_PLAN_ID &&
      !protectionPlanFile?.data
    ) {
      return true
    }
    if (
      (IS_DRIVER_STEP && (!clientFormData.email || !clientFormData.phone || !driversLicenseFile?.data)) ||
      isLoadingVerification
    ) {
      return true
    }
  }

  const memoizedValues = useMemo(
    () => ({
      isUploadModalVisible,
      setIsUploadModalVisible,
      totalPrice: calculatedPrice?.total,
      calculatePriceError,
      formRef,
      emailVerificationStatus,
      verificationError,
      clientFormData,
      selectedProtectionPlanId,
      driversLicenseFile,
      carId,
      selectedExtras
    }),
    [
      isUploadModalVisible,
      calculatedPrice?.total,
      calculatePriceError,
      verificationError,
      emailVerificationStatus,
      clientFormData,
      selectedProtectionPlanId,
      driversLicenseFile,
      carId,
      selectedExtras
    ]
  )

  return (
    <QuotaContext.Provider value={memoizedValues}>
      <Routes>
        <Route
          path={PROTECTION_PLAN_STEP_URL as string}
          element={
            <ProtectionPlan
              protectionPlanFile={protectionPlanFile}
              setProtectionPlanFile={setProtectionPlanFile}
              setSelectedProtectionPlanId={setSelectedProtectionPlanId}
            />
          }
        />
        <Route
          path={EXTRAS_STEP_URL as string}
          element={<ExtrasOptions setSelectedExtras={setSelectedExtras} />}
        />
        <Route
          path={DRIVER_STEP_URL as string}
          element={
            <ClientDetails
              sendQuota={sendQuota}
              setClientFormData={setClientFormData}
              handleUploadDriversLicenseFile={handleUploadDriversLicenseFile}
              sendEmailVerificationRequest={sendEmailVerificationRequest}
            />
          }
        />
        <Route
          path={CONFIRM_STEP_URL as string}
          element={
            <ThankYou
              clientFormData={clientFormData}
              quotaDetails={quotaDetails}
            />
          }
        />
      </Routes>
      {!location.pathname.includes(CONFIRM_STEP_URL) && (
        <>
          <StickyButton
            onButtonClick={() => getOnButtonClick()}
            onDetailsClick={() => setIsPriceDetailsModalOpen(true)}
            name={IS_EXTRAS_STEP || IS_PROTECTION_PLAN_STEP ? 'Continue' : 'Submit'}
            emailVerificationStatus={emailVerificationStatus}
            onBackButtonClick={onBackButtonClick}
            price={calculatedPrice?.total}
            disabled={getDisabled()}
            isLoading={isLoadingVerification}
            isNextDisabled={Boolean(clientFormData.email && !clientFormData.emailCode)}
          />
          <PriceDetailsModal
            id={carId}
            isOpen={isPriceDetailsModalOpen}
            onClose={() => setIsPriceDetailsModalOpen(false)}
            priceData={calculatedPrice}
          />
        </>
      )}
    </QuotaContext.Provider>
  )
}
