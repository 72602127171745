import { FC } from 'react'
import './index.css'

type TCarDistanceBlock = {
  distanceFreeIncluded: number
}

export const DistanceBlock: FC<TCarDistanceBlock> = ({ distanceFreeIncluded }) => {
  return (
    <>
      <h4 className="block-title">Distance included</h4>
      <br />
      <div className="item-wrapper">
        <div className="item-details">
          <p className="item-name">${distanceFreeIncluded} mi/day</p>
          <p className="item-description">Distance included every day</p>
        </div>
      </div>
    </>
  )
}
