import { useEffect, useCallback, useState } from 'react'

import { apiV1, TAllowedMethods, TFetchOptions, TFetchResult } from '@api/v1'

export type UseApiReturn<T> = {
  data: T | null
  isLoading: boolean
  error: Error | null
  abort: () => void
}

type TPassedDepsTree = unknown[] | []

const useFetch = <T>({
  path,
  method,
  body,
  options,
  batchParams,
  deps = []
}: {
  path?: string
  method?: TAllowedMethods
  body?: T
  options?: TFetchOptions
  batchParams?: Array<{ path: string; method?: TAllowedMethods; body?: T; options?: TFetchOptions }>
  deps?: TPassedDepsTree
}): UseApiReturn<T> => {
  const [data, setData] = useState<T | T[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const abort = useCallback(() => {
    apiV1.abortRequest()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setData(null)
      setError(null)

      try {
        let result: TFetchResult<T>
        if (batchParams?.length) {
          result = await apiV1.batchRequestHandler<T>(batchParams)
        } else if (path && method) {
          result = await apiV1.requestHandler<T>(path, method, body, options)
        } else {
          throw new TypeError('Invalid request parameters')
        }
        setData(result.data)
        setError(result.error)
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unexpected error occurred'))
      } finally {
        setIsLoading(false)
      }
    }

    void fetchData()

    return () => {
      // @TODO: Implement proper error handling for react Components cause now it cancelling next request if previous failed
      // abort()
    }
  }, [...deps])

  return {
    data,
    isLoading,
    error,
    abort
  }
}

const useBatchRequest = <T>(
  batchParams: Array<{ path: string; method?: TAllowedMethods; body?: T; options?: TFetchOptions }>
): UseApiReturn<T> => {
  return useFetch<T>({
    batchParams
  })
}

const createApiHook = (method: TAllowedMethods) => {
  return <T>({
    url,
    body,
    options,
    deps
  }: {
    url: string
    body?: T
    options?: TFetchOptions
    deps?: TPassedDepsTree
  }): UseApiReturn<T> => {
    return useFetch<T>({
      path: url,
      method,
      body,
      options,
      deps
    })
  }
}

const useGet = createApiHook(TAllowedMethods.GET)
const usePost = createApiHook(TAllowedMethods.POST)
const usePut = createApiHook(TAllowedMethods.PUT)
const usePatch = createApiHook(TAllowedMethods.PATCH)

export { useGet, usePost, usePut, usePatch, useBatchRequest }
