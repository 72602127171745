import './index.css'
import AboutUsCar1 from './assets/AboutUsCar1.webp'
import AboutUsCar2 from './assets/AboutUsCar2.webp'
import { aboutUsDate } from './data'

export const AboutUs = () => {
  return (
    <section className="about-us-wrp">
      <img
        className="about-us-img"
        src={AboutUsCar1}
        alt="Elite emerald green car parked in a parking lot"
      />
      <div className="about-us-cnt">
        {aboutUsDate ? aboutUsDate.block1.map((item, index) => (
          <div
            className="about-us-content"
            key={index}
          >
            <h3 className="about-us-info">{item.info}</h3>
            <p className="about-us-about">{item.about}</p>
          </div>
        )) : null}
      </div>
      <img
        className="about-us-img"
        src={AboutUsCar2}
        alt="Elite dark-colored car parked in a parking lot"
      />
      <div className="about-us-cnt">
        {aboutUsDate ? aboutUsDate.block2.map((item, index) => (
          <div
            className="about-us-content"
            key={index}
          >
            <h3 className="about-us-info">{item.info}</h3>
            <p className="about-us-about">{item.about}</p>
          </div>
        )) : null}
      </div>
    </section>
  )
}
