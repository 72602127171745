import './index.css'
import { FC } from 'react'

import { URLParamsFormatter, QueryVoccab } from '@helpers/queryFormatter'
import { ResponsiveImage } from '@shared/components'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { TPopularVehiclesResp } from '@api/types'
import { CAR, CATALOG } from '@helpers/routes'
import { useGet } from '@hooks/useApi'
import CurrencyFormatter from '@helpers/currencyFormatter'

type TPopularCars = {
  renderLimit?: number
}

const PopularCars: FC<TPopularCars> = ({ renderLimit }) => {
  const popularVehicles = useGet<TPopularVehiclesResp>({
    url: '/popular_vehicles'
  })

  const vehiclesToRender = renderLimit ? popularVehicles?.data?.slice(0, renderLimit) : popularVehicles?.data

  return (
    <div className="cards-container">
      {vehiclesToRender?.map((car) => {
        return (
          <div
            className="card"
            key={car.id}
          >
            <div className="responsive-image-container">
              <ResponsiveImage
                urls={car.src}
                alt={`Interior view of ${car.model}`}
              />
            </div>
            <div className="popular-details">
              <h5 className="info">
                {car.maker}&nbsp;{car.model}
              </h5>
              <div className="price-info">
                <p className="price">
                  from&nbsp;
                  {CurrencyFormatter.format({
                    amount: car.price
                  })}
                  &#47; DAY
                </p>
                <p className="price-excluded">(excluded tax and fees)</p>
              </div>
            </div>
            <div className="card-buttons">
              <LinkButton
                to={URLParamsFormatter.formatPathParams(CAR(car.id), {
                  [QueryVoccab.pickUpLocationName]: car.location.name,
                  [QueryVoccab.pickUpLocationType]: car.location.type,
                  [QueryVoccab.pickUpLocationId]: car.location.id,
                  [QueryVoccab.rentDays]: car.minRentDays
                })}
                name="Details"
              />
              <LinkButton
                to={URLParamsFormatter.formatPathParams(CAR(car.id), {
                  [QueryVoccab.pickUpLocationName]: car.location.name,
                  [QueryVoccab.pickUpLocationType]: car.location.type,
                  [QueryVoccab.pickUpLocationId]: car.location.id,
                  [QueryVoccab.rentDays]: car.minRentDays
                })}
                name="Rent now"
                type="primary"
              />
            </div>
          </div>
        )
      })}
      {popularVehicles?.data?.length === 0 && <span className="no-cars">No cars available right now</span>}
      <div className="show-all-button-cnt">
        <LinkButton
          className="popular-button"
          to={CATALOG()}
          name="Show all"
        />
      </div>
    </div>
  )
}

export default PopularCars
