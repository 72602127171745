import { ChangeEvent } from 'react'

export const useFileUpload = (setFileToLocalStore: (fileData: FileData | null) => void) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (!selectedFile) return

    const reader = new FileReader()
    reader.onload = () => {
      setFileToLocalStore({ name: selectedFile.name, data: reader.result })
    }
    reader.onerror = () => {
      setFileToLocalStore(null)
    }

    reader.readAsDataURL(selectedFile)
  }

  return {
    handleFileChange
  }
}
