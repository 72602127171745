import './index.css'

import { FC, useState } from 'react'

import { TFilterTypes } from '@api/types'

import { TDynamicFilterParams } from '../Catalogue/Filters/FiltersGroup'

import { CustomSwitch } from './CustomSwitch'

type TSwitchFilter = {
  options: [number]
  label: string
  name: string
  filterType: TFilterTypes
  handleChange: (params: TDynamicFilterParams) => void
  defaultValue?: [boolean] | undefined
}

const SwitchFilter: FC<TSwitchFilter> = ({
  name, label, defaultValue, handleChange, filterType
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(Array.isArray(defaultValue) ? defaultValue[0] : false)

  const handleOnChange = () => {
    const updatedValue = !isChecked

    setIsChecked(updatedValue)
    handleChange({
      fieldName: name,
      selectedValues: [updatedValue],
      type: filterType
    })
  }

  return (
    <div className="wrapper">
      <CustomSwitch
        label={label}
        checked={isChecked}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default SwitchFilter
