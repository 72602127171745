import { Dayjs as TDayjs } from 'dayjs'

export const formattedDateTime = (dateTime: TDayjs) => {
  return dateTime.format('MM/DD/YYYY hh:mm A')
}

export const throttle = (fn, wait) => {
  let isThrottled = false
  let savedArgs
  let savedContext

  const later = () => {
    if (savedArgs) {
      fn.apply(savedContext, savedArgs)
      savedArgs = null
      savedContext = null
      setTimeout(later, wait)
    } else {
      isThrottled = false
    }
  }

  return function (...args) {
    if (isThrottled) {
      savedArgs = args
      savedContext = this
      return
    }

    fn.apply(this, args)
    isThrottled = true
    setTimeout(later, wait)
  }
}

export const pluralFormatter = new Intl.PluralRules('en')
