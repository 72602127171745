import './index.css'

import { FC, ReactNode } from 'react'

import { EmptyRadioButton, SelectedRadioButton } from '@shared/icons'
import { TPlan } from '@api/types'

type TProtectionPlanCardProps = {
  protectionCard: TPlan
  children: ReactNode
  setSelectedProtectionPlanId: (id: number) => void
  selectedProtectionPlanId: number | null
}

export const ProtectionPlanCard: FC<TProtectionPlanCardProps> = ({
  protectionCard,
  setSelectedProtectionPlanId,
  selectedProtectionPlanId,
  children
}) => {
  const isSelected = selectedProtectionPlanId === protectionCard.id

  const onCardItemClick = () => {
    if (!isSelected) {
      setSelectedProtectionPlanId(protectionCard.id)
    }
  }

  return (
    <details
      name="protectionCard"
      className="protectionCard-item"
    >
      <summary
        className="protectionCard-header"
        onClick={onCardItemClick}
      >
        <div className="protectionCard-text-container">
          <div className="protectionCard-title">{protectionCard.title}</div>
          <div className="protectionCard-description">{protectionCard.description}</div>
          <div className="protectionCard-price">
            {protectionCard.price ? (
              <>
                {protectionCard.type}
                {protectionCard.price}&#47;DAY
              </>
            ) : (
              <span>Included</span>
            )}
          </div>
        </div>
        <div className="protectionCard-icon-container">
          {isSelected ? <SelectedRadioButton /> : <EmptyRadioButton />}
        </div>
      </summary>
      {children}
    </details>
  )
}
