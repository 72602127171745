import './index.css'

import { FC } from 'react'

type CarRatesProps = {
  rateDay: string
  rateWeek: string
  rateMonth: string
}

export const CarRates: FC<CarRatesProps> = ({ rateDay, rateWeek, rateMonth }) => {
  return (
    <>
      <p className="rate-detail">${rateDay} &#47; day</p>
      <p className="rate-detail">${rateWeek} &#47; week</p>
      <p className="rate-detail">${rateMonth} &#47; month</p>
    </>
  )
}
