import './index.css'

import { Accordion } from '@shared/components'

import { faqList } from '../../data'

const FAQ = () => {
  return (
    <section
      className="faq-section"
      id="faq"
    >
      <h2 className="faq-title">FAQ</h2>
      <div className="accordion-container">
        {faqList?.map((faq) => (
          <div key={faq.id}>
            <Accordion summary={faq.question}>
              <p className="faq-answer">{faq.answer}</p>
            </Accordion>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FAQ
