import { Section } from '../types'

export const prohibitedDate: Section[] = [
  {
    title: 'Prohibited activities',
    subsections: [
      {
        contentDot:
          'If you engage in any prohibited uses with a vehicle booked through CarAgeRental, we may apply the following consequences'
      },
      {
        contentDot:
          'If you engage in any prohibited uses with a vehicle booked through CarAgeRental, we may apply the following consequences'
      },
      {
        contentDot: 'Void your protection for any physical damage to a host’s vehicle'
      },
      {
        contentDot:
          'Lower your liability coverage to state minimum limits or nullify liability coverage where allowed by state law'
      },
      {
        contentDot: 'Charge a violation fee'
      },
      {
        contentDot: 'Temporarily suspend your use of the platform'
      },
      {
        contentDot: 'Remove you from CarAgeRental'
      },
      {
        subtitle: 'Prohibited vehicle uses and activities include:',
        contentDot:
          'Permitting someone who’s not an Approved Driver* to drive a vehicle booked via CarAgeRental'
      },
      {
        contentDot:
          'Driving a manual transmission car without being an expert in the use of clutches and manual transmissions'
      },
      {
        contentDot:
          'If the drivetrain of a manual transmission car is damaged, the primary guest will be presumed to be at fault and will be held fully liable for repair of any damaged components of the car'
      },
      {
        contentDot:
          'Allowing the vehicle to be pushed or towed by anyone other than an authorized law enforcement or service vehicle'
      },
      {
        subtitle: 'Using a vehicle booked through CarAgeRental:',
        contentDot: 'to tow or push anything'
      },
      {
        contentDot: 'for “off roading” on undeveloped or unimproved roads'
      },
      {
        contentDot: 'in any race, test, or competition'
      },
      {
        contentDot:
          'with the intention to cause damage, or with wanton, willful, or reckless disregard for safety'
      },
      {
        contentDot:
          'to carry persons or property "for hire" or to carry persons or property for compensation or a fee, including, but not limited to, taxi services, TNC services, and/or parcel, food, or grocery delivery services. You may, however, use the car for business purposes, such as attending meetings and carrying associated materials'
      },
      {
        contentDot:
          'unless a trip has been booked, using vehicles without a reservation or outside your trip time constitutes unauthorized use'
      },
      {
        subtitle: 'While the driver is under the influence of:',
        contentDot: 'alcohol above the legal limit'
      },
      {
        contentDot:
          'any drug or medication under the effects of which the operation of a vehicle is prohibited or not recommended'
      },
      {
        contentDot:
          'when it has been loaded beyond its rated capacity or with more passengers than the vehicle has seat belts'
      },
      {
        contentDot:
          'without a valid license, or if you do not meet our requirements for booking a car. It’s your responsibility to inform us when your license is expired or suspended, or if you believe you may no longer meet our requirements for booking a car'
      },
      {
        contentDot: 'to transport any flammable, toxic, volatile, poisonous, dangerous, or illegal substances'
      },
      {
        contentDot:
          'to transport a pet in a vehicle that hasn’t been labeled as “pet friendly” without the host’s explicit consent, except in the case of service animals as defined by the ADA. Guests who bring service animals on their trip may still be subject to a cleaning violation fee as per CarAgeRental’s cleaning policy.'
      },
      {
        contentDot: 'Making any unapproved alterations, additions, or improvements to any vehicle'
      },
      {
        contentDot: 'If you’re a guest in the US who has a safety concern, let your host know'
      },
      {
        contentDot:
          'If you attempted to reach them but haven’t heard back after at least 30 minutes, leave a voicemail and we will respond ASAP.'
      },
      {
        contentDot: 'Smoking in the car'
      },
      {
        contentDot: 'Fueling a vehicle with an improper type of fuel'
      },
      {
        contentDot: 'Leaving a vehicle unattended while it is running or with the keys in the vehicle'
      },
      {
        contentDot: 'Making fraudulent reports about a vehicle or a host'
      },
      {
        content:
          '*Only CarAgeRental-approved drivers can book and drive a car during a CarAgeRental trip. To be an “Approved Driver” means that the guest has provided and updated all required documentation, continues to meet all of our requirements for booking a car, is currently in good standing, and, as per our Terms of Service, is listed on the reservation. Guests may request and be approved to add an additional Approved Driver to a trip. All drivers shall be jointly and severally liable for the trip, but the primary guest (meaning the guest who books the trip) shall remain primarily liable.'
      }
    ]
  }
]
