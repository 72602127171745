import { celebrity1, celebrity2, celebrity3 } from '../assets'

export const LearnView = () => {
  return (
    <>
      <div className="learn-celebrities-block">
        <div className="learn-celebrity-lerge">
          <img
            src={celebrity1}
            alt="A celebrity sitting in a car with the door open"
          />
        </div>
        <div className="learn-celebrity-small">
          <img
            src={celebrity2}
            alt="A celebrity standing in front of a yellow premium car"
          />
        </div>
        <div className="learn-celebrity-small">
          <img
            src={celebrity3}
            alt="Two celebrities sitting on the roof of a premium car"
          />
        </div>
      </div>
    </>
  )
}
