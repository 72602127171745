import './services/error-tracing'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import Analytics from '@analytics/analytics'

import App from './App'

import './main.css'

Analytics.init()

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App />
  </StrictMode>
)
