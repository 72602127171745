import './index.css'

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useBatchRequest } from '@hooks/useApi'
import { TPopularVehiclesResp, TVehicleCategoriesResp } from '@api/types'
import { Header, SelectPickupAndReturn } from '@shared/components'
import { URLParamsFormatter } from '@helpers/queryFormatter'
import { CATALOG } from '@helpers/routes'

import FAQ from './components/FAQ/FAQ'
import Steps from './components/Steps/Steps'
import { Celebrities } from './components/Celebrities/Celebrities'
import { OurWhipss } from './components/OurWhipss/OurWhipss'
import ComingSoonModal from './components/ComingSoonModal'

export const HomeContainer = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [popularVehicles, setPopularVehicles] = useState<TPopularVehiclesResp[] | null>(null)
  const [ourFleet, setOurFleet] = useState<TVehicleCategoriesResp | null>(null)

  const handleLocationSearch = () => {
    navigate(URLParamsFormatter.formatPathParams(CATALOG()))
  }

  const { data } = useBatchRequest<[TPopularVehiclesResp, TVehicleCategoriesResp]>([
    {
      path: '/popular_vehicles'
    },
    {
      path: '/vehicle_categories'
    }
  ])
  const [popularVehiclesData, vehicleCategoriesData] = data ?? [null, null]

  // @TODO: fix types for batch response
  useEffect(() => {
    if (popularVehiclesData) {
      setPopularVehicles(popularVehiclesData.data)
    }
    if (vehicleCategoriesData) {
      setOurFleet(vehicleCategoriesData.data)
    }
  }, [popularVehiclesData, vehicleCategoriesData])

  return (
    <>
      <article className="home-cnt">
        <div className="main-wrapper">
          <Header setIsModalOpen={setIsModalOpen} />
          <div className="pickup-wrapper">
            <h1 className="home-title">Car rental</h1>
            <SelectPickupAndReturn
              onSearch={handleLocationSearch}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </div>
        <Celebrities setIsModalOpen={setIsModalOpen} />
        <OurWhipss
          popularVehicles={popularVehicles}
          ourFleet={ourFleet}
        />
        <Steps />
        <FAQ />
      </article>
      <ComingSoonModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}
