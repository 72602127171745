import CardCar1 from './assets/cardImg/cardCar1.webp'
import CardCar2 from './assets/cardImg/cardCar2.webp'
import CardCar3 from './assets/cardImg/cardCar3.webp'

export const CardCars = [
  {
    id: 1,
    nameCar: 'BMW 530i',
    price: '300',
    imgCar: CardCar1,
    buttonView: 'View more',
    buttonRent: 'Rent a car'
  },
  {
    id: 2,
    nameCar: 'Porsche Cayenne',
    price: '300',
    imgCar: CardCar2,
    buttonView: 'View more',
    buttonRent: 'Rent a car'
  },
  {
    id: 3,
    nameCar: 'Mercedes Benz A220',
    price: '300',
    imgCar: CardCar3,
    buttonView: 'View more',
    buttonRent: 'Rent a car'
  }
]

export const page404Date = {
  title: 'Page not found',
  about: 'Use menu to find what you are looking for, or go back to the home page',
  button: 'Go to homepage'
}

export const page500Date = {
  title: 'Internal server error',
  subtitle: 'Something went wrong',
  about: 'We are sorry, this was unexpected :(',
  button: 'Go to homepage'
}

export const findAnyInfoDate = {
  title: 'Sorry, we could not find the car based on your search words',
  about: 'Change your wording to keep searching',
  button: 'Edit search data'
}

export const carIsBookedDate = {
  title: 'This car is already reserved by another guest',
  subtitle: 'Next available date: ',
  date: '23.10.2024',
  about: 'Check the calendar for this car or find another car',
  button: 'Check our calendar',
  buttonSecondary: 'Explore our fleet'
}
