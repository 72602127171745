import { Section } from '../types'

export const privacyData: Section[] = [
  {
    subsections: [
      {
        content:
          'CarAgeRental LLC. and its subsidiaries (collectively, “CarAgeRental”, “we”, or “us”) care about privacy and want you to be familiar with how we collect, use, process, and disclose your personal information. This Privacy Policy describes our privacy practices in connection with our websites and applications that link to this Privacy Policy (collectively, the “Services”) and our offline interactions with you in settings where we post this Privacy Policy.'
      }
    ]
  },
  {
    title: 'Personal information we collect',
    subsections: [
      {
        content:
          'We collect three categories of personal information - personal information you give us; personal information automatically collected from your use of the Services; and personal information from third-party sources.'
      }
    ]
  },
  {
    title: 'Personal information you give us',
    subsections: [
      {
        content:
          'Reservation data. When you book a reservation with us, we require certain personal information to open your profile as a Guest, such as your name, email address, and phone.'
      },
      {
        content:
          'Profile data. We may also ask you to provide additional profile information to use certain features of the Services which may include street addresses, phone numbers, driver’s license number, date of issuance and issuing country and/or state, profile photos, employer, city, school, biography, and date of birth.'
      },
      {
        content:
          'Payment data. We collect your digital payment details, bank account or payment card numbers, and transaction information in connection with a potential or actual transaction, which may be processed and stored by one or more third party payment service providers or digital payments companies.'
      },
      {
        content:
          'Identity verification data. In some instances, we may collect identity verification information such as a photograph or scanned copy of a driver’s license, passport, national ID card, or payment card, last four digits of your Social Security number, social insurance number, social media account information, driver’s/motor vehicle record, insurance information, or other forms of identification information. Where we request that you withhold certain information (such as obscuring or redacting aspects of identification information), please do so.'
      },
      {
        content:
          'Communications. When you communicate with CarAgeRental, including via phone, email, or chat, or use the Services to communicate with other users, we collect information about your communication and any information you choose to provide.'
      }
    ]
  },
  {
    title: 'Personal information we automatically collect',
    subsections: [
      {
        content:
          'Usage data. We collect information about your interactions with the Services, such as the pages or other content you view, your searches, bookings you have made, how long you spent on a page or screen, sites from which you link or browse to in the Services, navigation paths between pages or screens, information about your activity on a page or screen, access time, duration of access, and other actions on the Services.'
      },
      {
        content:
          'Location data. When you use certain features of the Services, we may collect information about your approximate location (e.g., city/town associated with your IP address). When you opt in to use our location sharing feature, we may collect the precise location information of your mobile device. Keep in mind that most mobile devices allow you to control or disable the use of location services by any application on your mobile device in the device’s settings menu.'
      },
      {
        content:
          'Device data. We collect information about your computer or mobile device, such as its operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, unique device identifiers, or general location information such as city, state, or geographic area.'
      },
      {
        content:
          'Trip data. We collect transactional information related to the trips you take through the Services, including the date and time of your trip, amounts charged, and other related trip details.'
      },
      {
        content:
          'In-vehicle device data. If a vehicle has a CarAgeRental device installed, we may remotely collect certain information about the vehicle. Depending on the make, model, and year of the vehicle, this information may include, GPS location, car battery voltage, fuel level, odometer reading, malfunction indicator lamp (e.g., a check engine light), door state (e.g., locked/unlocked), engine state (e.g., stopped/running), and VIN.'
      },
      {
        content:
          'Cookies and similar technology. When you access the Services, we (including companies we work with) may place small data files on your computer or other device. These data files may be cookies, clear gifs, pixel tags, e-tags, “Flash cookies”, or other local storage provided by your browser or associated applications (collectively “Cookies”). At this time, we do not respond to browser ‘Do-Not-Track’ signals.'
      }
    ]
  },
  {
    title: 'Personal information we collect from third-party sources',
    subsections: [
      {
        content:
          'Third-party in-vehicle devices. If your vehicle, or a vehicle you book through the Services, includes an in-vehicle device or system operated by a third-party service (including vehicle manufacturers) or installed by the host, that host or service may record information about your use of the car. These devices or systems may monitor the car’s condition, performance and operation, track fuel consumption, distance traveled, location, and other information. The host and/or third-party service will be solely responsible for its use of such information but may disclose such information to CarAgeRental, which we will use in accordance with this Privacy Policy.'
      },
      {
        content:
          'Background check services. To the extent permitted by applicable laws, CarAgeRental may collect background information about you from public records, background check providers, or other screening services, including credit reports and information about criminal convictions or from sex offender registries. We may use your information, including your full name and date of birth, to obtain such reports.'
      },
      {
        content:
          'Other sources. To the extent permitted by applicable law, we may receive additional information about you, such as demographic data, fraud detection information, or data from credit bureaus and other third-party data providers.'
      },
      {
        content:
          'We may combine the information about you that we receive from third-party sources with other information we have about you.'
      }
    ]
  },
  {
    title: 'How we use your personal information',
    subsections: [
      {
        content:
          'We use, store, and process your personal information to provide and improve the Services and for security and safety purposes. For example, we may use your information:'
      },
      {
        subtitle: 'To provide the Services, including to:',
        contentDot: 'Provide and operate the Services'
      },
      {
        contentDot: 'Provide customer support'
      },
      {
        contentDot:
          'Send you service, support, and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you at any telephone number, by placing a voice call or through text (SMS) or email messaging'
      },
      {
        contentDot: 'Process transactions and send notices about your transactions'
      },
      {
        contentDot: 'Personalize or customize your user experience'
      },
      {
        contentDot:
          'Enable you to communicate with other parties, including by sending them messages or other information during the booking process'
      },
      {
        contentDot: 'Facilitate your referral invitations'
      },
      {
        contentDot:
          'Send your requests for reviews, for fraud detection and prevention, and for any purpose you authorize at the time of collection'
      },
      {
        contentDot:
          'Administer referral programs, rewards, surveys, contests, or other promotional activities or sponsored events in which you participate'
      },
      {
        subtitle: 'For research and development:',
        content:
          'We may use your personal information to analyze and improve the Services and to develop new products and services, including by studying our user demographics of the Services. We may also create aggregated, de-identified, or other anonymous data from your personal information. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Services and promote our business.'
      },
      {
        subtitle: 'For marketing and advertising:',
        content:
          'We and our third party advertising partners may collect and use your personal information for marketing and advertising purposes:'
      },
      {
        content:
          'Direct marketing. We may send you CarAgeRental-related marketing communications as permitted by law. You will have the ability to opt-out of our marketing and promotional communications by contacting us at info@caragerental.com.'
      },
      {
        content:
          'Interest-based advertising. We may contract with third party advertising and social media companies to display ads on the Services and other sites. These companies may use cookies and similar technologies to collect information about you (including the device data, online activity data, and/or geolocation data described above) over time across our Services and other sites and services or your interaction with our emails, and use that information to serve ads that they think will interest you. These ads are known as “interest-based advertisements”. You can learn more about your choices for limiting interest-based advertising, in the Interest-based advertising choices section below.'
      },
      {
        subtitle: 'For security and safety, including to:',
        contentDot:
          'Verify your identity or authenticate information that you provide, including during profile creation'
      },
      {
        contentDot: 'Resolve disputes, collect fees, and troubleshoot problems'
      },
      {
        contentDot:
          'Detect, prevent, and/or remediate fraud, abuse, security incidents, or other potentially harmful, prohibited, or illegal activities'
      },
      {
        contentDot:
          'Determine your likelihood of getting into an accident or of making an insurance claim, such as by checking your auto insurance score'
      },
      {
        contentDot:
          'Using information from your mobile or in-vehicle device to identify unsafe driving behavior, including speeding or harsh braking and acceleration, and to raise awareness regarding such behaviors'
      },
      {
        contentDot:
          'Detect, prevent, or remediate violations of and enforce our Terms of Service and Policies.'
      },
      {
        contentDot: 'Manage and protect our information technology infrastructure'
      },
      {
        contentDot: 'Conduct investigations and risk assessments'
      },
      {
        contentDot:
          'Conduct checks against databases and information sources (such as but not limited to public government databases)'
      },
      {
        contentDot: 'Perform creditworthiness and solvency checks'
      },
      {
        subtitle: 'To comply with law:',
        content:
          'We use your personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal processes, such as to respond to subpoenas or requests from government authorities.'
      },
      {
        subtitle: 'With your consent:',
        content:
          'In some cases, we may specifically ask for your consent to process your personal information.'
      },
      {
        content:
          'We may also use your personal information as described elsewhere in this Privacy Policy or as disclosed to you at the time of collection.'
      }
    ]
  },
  {
    titleLarge: 'How we disclose your personal information',
    subsections: [
      {
        subtitleCentre: 'With your consent',
        content: 'We may share your information at your direction or with your consent.'
      },
      {
        subtitleCentre: 'Sharing between hosts and guests',
        content:
          'If you agree to a booking through the Services, we may provide your information to the other party in that transaction as reasonably necessary to facilitate the transaction. For example, CarAgeRental may provide your mobile phone number to facilitate communication, your driver’s license information to confirm license validity, or your photograph to facilitate identification. We will also share the proposed delivery location with the other party. You have the right, but not the obligation, to take pictures reporting the condition of the vehicle prior to the start of the trip and send it to trips@caragerental.com with the Subject Line stating “Pre-Trip Photos - Reservation#(number of your reservation)”.'
      },
      {
        subtitleCentre: 'Service providers',
        content:
          'We may share information with vendors and service providers who support the operation of the Services and business and who need access to such information to carry out their work for us (including, for example, web hosting, analytics, payment processing, email delivery, marketing, insurance, claims administration, and customer support services). In some cases, the service provider may directly collect the information from you on our behalf. These service providers may use your personal information only as directed or authorized by us.'
      },
      {
        subtitleCentre: 'Third-party platforms and social media networks',
        content:
          'If you have enabled features or functionality that connect the Services to a third-party platform or social media network (such as by logging in to the Services using your account with the third party, providing your API key or similar access token for the Services to a third party, or otherwise linking your Services account to a third-party’s services), we may disclose to the third-party platform or social media network the personal information necessary to facilitate the connection or that you authorized us to share. We do not control the third party’s use of your personal information.'
      },
      {
        subtitleCentre: 'Professional advisors',
        content:
          'We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors, and insurers, where necessary in the course of the professional services that they render to us.'
      },
      {
        subtitleCentre: 'Business transfers',
        content:
          'We may sell, transfer, or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization, or sale of assets, or in the event of bankruptcy or dissolution.'
      },
      {
        subtitleCentre: 'Responding to legal requests, preventing harm, and protecting our rights',
        content:
          'We may disclose your personal information to courts, law enforcement, governmental or tax authorities, or third parties. We will make such disclosure to the extent we are required or permitted to do so by applicable law or where we consider such disclosure is reasonably necessary to comply with our legal obligations or legal process, to respond to claims asserted against us, and for the security and safety purposes described above. We may also disclose your information in response to valid legal requests relating to criminal investigations or alleged or suspected illegal activity or any other activity that may expose CarAgeRental, you, or any other user, or to protect the rights, property, or personal safety of CarAgeRental, our users, or others.'
      },
      {
        content:
          'We may also disclose your personal information as described elsewhere in this Privacy Policy or as disclosed to you at the time of collection.'
      }
    ]
  },
  {
    titleLarge: 'Your preferences and choices',
    subsections: [
      {
        subtitleCentre: 'Correct and update',
        content:
          'You can review, correct, update, and edit certain information that has been previously provided to us by you at any time by sending us an email at info@caragerental.com with the Subject Line: “Information Update - (Your Name)” and including all the necessary requested changes in the e-mail. For your protection, we may need to verify your identity before implementing your request.'
      },
      {
        subtitleCentre: 'Account closure',
        content:
          'If you wish to close your account and request deletion of your personal information, please send an email to info@caragerental.com with a Subject Line: “Account Closure - (Your Name)”.'
      },
      {
        content:
          'Please note that we may be unable to delete information needed to comply with applicable laws, detect or prevent fraud, collect any fees owed, resolve disputes, assist with or process claims, troubleshoot problems, assist with any audits and investigations, to enforce our Terms of Service and Policies, and take other actions reasonably necessary, permitted, or required by applicable law. There may also be residual information that will remain within our databases and other records, which will not be removed.'
      },
      {
        subtitleCentre: 'Interest-based advertising choices',
        content:
          'Your choices for limiting use of your personal information for interest-based advertising include:'
      },
      {
        content:
          'Blocking cookies in your browser. Most browsers let you remove or reject cookies, including cookies used for interest-based advertising. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. For more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit www.allaboutcookies.org.'
      },
      {
        content:
          'Using privacy plug-ins or browsers. You can block sites from setting cookies for interest-based ads by using a browser with privacy features, like Brave, or installing browser plugins like Privacy Badger, Ghostery, or uBlock Origin, and configuring them to block third party cookies/trackers.'
      },
      {
        subtitleCentre: 'Choosing not to share your personal information',
        content:
          'Where we are required by law to collect your personal information, or where we need your personal information in order to provide the Services to you, if you do not provide this information when requested (or we later delete it at your request), we may not be able to provide you with the Services.'
      },
      {
        subtitleCentre: 'Security',
        content:
          'We employ a number of technical, physical, and organizational measures designed to protect information against unauthorized access, destruction, or alteration while it is under our control. However, no method of transmitting or storing information can be 100% secure and we cannot guarantee the security of your personal information.'
      }
    ]
  },
  {
    titleLarge: 'Other important information',
    subsections: [
      {
        subtitleCentre: 'Cross border transfer',
        content:
          'The Services are controlled and operated from the United States. Your personal information may be used, stored, and processed in any country where we have facilities or in which we engage service providers. These locations may be outside of your state, province, or country of residence, and may have different and/or less protective data protection rules than those of your state, province, or country. As a result, this information may be subject to access requests from governments, courts, regulatory agencies, security authorities, or law enforcement in those jurisdictions according to the laws in those jurisdictions.'
      },
      {
        subtitleCentre: 'Sensitive information',
        content:
          'We ask that you not send us, and you not disclose, any sensitive information (e.g., Social Security numbers, social insurance numbers, passports, information related to racial or ethnic origin, or health) on or through the Services or otherwise to us unless specifically requested.'
      },
      {
        subtitleCentre: 'Children',
        content:
          'The Services are not intended for anyone under the age of 18 and we do not knowingly collect personal information from users under the age of 18. If a child under the age of 18 has already provided us with personal information, his or her parent or guardian may contact us to request that we delete it.'
      },
      {
        subtitleCentre: 'Third-party privacy practices',
        content:
          'This Privacy Policy addresses only the use and disclosure of information collected by CarAgeRental. This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any site or service to which the Services link. The inclusion of a link on the Services does not imply endorsement of the linked site or service by us or by our affiliates. If you disclose your information to others, or if you are directed to a third-party website, their privacy notices and practices will apply.'
      },
      {
        subtitleCentre: 'Translations',
        content:
          'Where CarAgeRental has provided you with a version of this Privacy Policy in a language other than English, in case of any wording discrepancies between such version and the English version, the English wording takes precedence.'
      },
      {
        subtitleCentre: 'Changes to this privacy policy',
        content:
          'We may change this Privacy Policy. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the Services or as otherwise indicated. Your use of the Services following these changes means that you accept the revised Privacy Policy. If you don’t agree to these changes, you can contact us to close your account.'
      },
      {
        subtitleCentre: 'Contact',
        content:
          'CarAgeRental welcomes your questions and comments about privacy. Please feel free to contact us at info@caragerental.com.'
      },
      {
        subtitleCentre: 'Information for California residents',
        content:
          'Scope. This section applies only to California residents entitled to receive the information in this section under the California Consumer Privacy Act of 2018 ("CCPA"). It describes how we collect, use, and share Personal Information of California residents when we act as a "business" as defined under the CCPA, and their rights with respect to their Personal Information. For purposes of this section, "Personal Information" has the meaning given in the CCPA but does not include information excluded from the scope of the CCPA. In some cases, we may provide a different privacy notice to certain categories of California residents, such as job applicants, in which case that notice will apply instead of this section.'
      },
      {
        subtitleCentre: 'Your California privacy rights',
        content: 'As a California resident, you have the rights listed below.'
      },
      {
        content:
          'Information. You can request the following information about how we have collected and used your PersonalInformation'
      },
      {
        contentDot: 'The categories of Personal Information that we have collected'
      },
      {
        contentDot: 'The categories of sources from which we collected Personal Information'
      },
      {
        contentDot: 'The business or commercial purpose for collecting Personal Information'
      },
      {
        contentDot: 'The categories of third parties with whom we share Personal Information'
      },
      {
        contentDot:
          'Whether we have disclosed your Personal Information for a business purpose, and if so, the categories of Personal Information received by each category of third-party recipient'
      },
      {
        contentDot:
          'Whether we have sold your Personal Information, and if so, the categories of Personal Information received by each category of third-party recipient'
      },
      {
        content:
          'Access. You can request a copy of the Personal Information that we have collected about you during the past 12 months.'
      },
      {
        content:
          'Deletion. You can request that we delete the Personal Information that we have collected from you.'
      },
      {
        content:
          'Nondiscrimination. You are entitled to exercise the rights described above free from discrimination in the form of any unlawful denial of service, increase in the price of services, decrease in service quality, or suggestion that you may be penalized for exercising your rights.'
      },
      {
        subtitleCentre: 'We do not sell your Personal Information',
        content:
          'Based on our understanding of the term "sell" under the CCPA, we do not sell personal information to third parties and have not sold Personal Information during the twelve months preceding the "last revised" date of this Privacy Policy. However, like many companies online, we use services provided by Google, Facebook, and others that help deliver interest-based ads to you as described in the section above entitled Interest-based advertising. We describe how you can opt-out of use of your Personal Information for interest-based advertising in the section entitled Interest-based advertising choices.'
      },
      {
        subtitleCentre: 'How to exercise your rights',
        content:
          'You can request to exercise your information, access, and deletion rights by contacting us at info@caragerental.com.'
      },
      {
        content:
          'We may decline your request where required or permitted by law. We will need to confirm your identity to process your requests and we reserve the right to confirm your California residency as well. Government identification may be required. You may designate an authorized agent to make a request on your behalf by providing a valid power of attorney, the requester’s valid government-issued identification, and the authorized agent’s valid government issued identification. We cannot process your request if you do not provide us with sufficient detail to allow us to understand and respond to it. You should transmit your government-issued identification or any other sensitive information to us only as instructed. Email is not a secure means of communication and you should not email your government-issued identification or any other sensitive information to us.'
      },
      {
        subtitleCentre: 'Cross-Border Data Transfer',
        content:
          'If we transfer your personal information from Europe to a country outside of Europe such that we are required to apply additional safeguards to your personal information under European data protection laws, we will do so, for example, by implementing the standard contractual clauses adopted by the European Commission. Similarly, if we transfer your personal information from the United Kingdom to a country outside of the United Kingdom such that we are required to apply additional safeguards to your personal information under data protection laws of the United Kingdom, we will do so, for example, by implementing the applicable standard contractual clauses. Please contact us at info@caragerental.com for further information about any such transfers or the specific safeguards applied.'
      }
    ]
  }
]
