import './index.css'

import { ReactNode, FC } from 'react'
import cn from 'classnames'

import { Search } from '@shared/icons'
import Analytics from '@analytics/analytics'

type TButtonProps = {
  name: string
  onClick: () => void
  className?: string
  showIcon?: boolean
  disabled?: boolean
  isLoading?: boolean
  size?: 'medium' | 'large'
  type?: 'primary' | 'secondary' | 'linkSecondary' // primary -> black, secondary -> white, linkSecondary -> gray
  children?: ReactNode
}

export const Button: FC<TButtonProps> = ({
  name,
  onClick,
  className,
  showIcon = false,
  disabled = false,
  type = 'primary',
  size = 'medium',
  isLoading = false,
  children
}) => {
  const handleOnClick = () => {
    Analytics.send('button-click', {
      name
    })

    onClick()
  }

  return (
    <button
      className={cn('btn', className, {
        '-primary': type === 'primary',
        '-secondary': type === 'secondary',
        '-linkSecondary': type === 'linkSecondary',
        '-medium': size === 'medium',
        '-large': size === 'large'
      })}
      onClick={!disabled ? handleOnClick : undefined}
      disabled={isLoading || disabled}
    >
      <span
        className={cn({
          loader: isLoading
        })}
      />
      <span className="btn-content">
        {showIcon && <Search />}
        {name}
        {children}
      </span>
    </button>
  )
}
