import './index.css'

import { Link } from 'react-router-dom'

import LinkButton from '@shared/components/LinkButton/LinkButton'
import dayjsExtended from '@helpers/dayjsExtended'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'
import { CATALOG, LEARN_MORE } from '@helpers/routes'

import { aboutText } from '../../data'

import { CelebritiesView } from './CelebritiesView'

const MIN_RENT_DAYS = 2

export const Celebrities = () => {
  return (
    <section
      className="celebrities-cnt"
      id="about"
    >
      <div className="about-title-section">
        <h2 className="about-title">{aboutText.titleMain}</h2>
        <p className="about-description">{aboutText.subtitle}</p>
      </div>
      <div className="celebrities">
        <CelebritiesView />
      </div>
      <section className="about-subtitle-section">
        <Link
          to={LEARN_MORE}
          className="about-subtitle"
        >
          We get you
        </Link>
        <p className="about-description">{aboutText.subtitleSecondary}</p>
      </section>
      <div className="about-button-wrapper">
        <LinkButton
          name="Cars available now"
          to={URLParamsFormatter.formatPathParams(CATALOG(), {
            [QueryVoccab.dropOffTime]: dayjsExtended.utc().add(MIN_RENT_DAYS, 'days').format()
          })}
        />
      </div>
    </section>
  )
}
