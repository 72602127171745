export const Dropzone = ({ onDrop, children }) => {
  const handleDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) {
      onDrop(files)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'
  }

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {children}
    </div>
  )
}
