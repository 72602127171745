import './index.css'

import { TCarExtra } from '@api/types'
import { FC } from 'react'

type ExtrasProps = {
  extras: TCarExtra[]
}

export const Extras: FC<ExtrasProps> = ({ extras }) => {
  return (
    <>
      <h4 className="block-title ">Extras</h4>
      <div className="services">
        {extras?.map((service, index) => (
          <div
            className="service-item"
            key={index}
          >
            <img
              src={service.iconUrl || ''}
              alt={service.title ? `${service.title} icon` : 'Service icon'}
            />
            <div>
              <span className="service-name">{service.title}</span>&nbsp;
              <span className="service-description">{service.description}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
