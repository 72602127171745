import './index.css'

import { FC } from 'react'

import CurrencyFormatter from '@helpers/currencyFormatter'
import { TCalculatePriceRes } from '@api/types/calculate_price'

type TPriceDetails = {
  priceData: TCalculatePriceRes
}

const PriceDetails: FC<TPriceDetails> = ({ priceData }) =>
  priceData?.options.length ? (
    <ul className="calculated-price-cnt">
      {priceData.options.map(({ name, total }) => (
        <li
          key={name}
          className="price-element"
        >
          <span>{name}</span>
          <span>{total}</span>
        </li>
      ))}
      <li className="price-element total">
        <span>Total price</span>
        <b>
          {CurrencyFormatter.format({
            amount: priceData.total
          })}
        </b>
      </li>
    </ul>
  ) : null

export default PriceDetails
