export const sanitizeFields = (obj: Record<string, any>): Record<string, any> => {
  const cleanedObject: Record<string, any> = {}

  for (const key in obj) {
    const value = obj[key]

    if (value !== null && value !== undefined && !Number.isNaN(value)) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        const sanitizedValue = sanitizeFields(value)

        // @INFO Only add the sanitized object if it's not empty
        if (Object.keys(sanitizedValue).length > 0) {
          cleanedObject[key] = sanitizedValue
        }
      } else {
        cleanedObject[key] = value
      }
    }
  }

  return cleanedObject
}
