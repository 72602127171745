import './index.css'
import { LearnView } from './components/LearnView'
import { learnMoreDate } from './data'
import { learnMoreCar } from './assets'
import { Link } from 'react-router-dom'
import { CATALOG } from '@helpers/routes'

export const LearnMore = () => {
  return (
    <main className="learn-wrp">
      <section className="learn-cnt">
        <div className="learn-title-section">
          <h2 className="learn-title">{learnMoreDate.title}</h2>
          <p className="learn-description">{learnMoreDate.about}</p>
        </div>
        <div className="learn-celebrities">
          <LearnView />
        </div>
      </section>
      <section className="learn-midle-section">
        <div className="learn-img-cnt">
          <img
            className="learn-img"
            src={learnMoreCar}
            alt="A premium car, embodying the luxury of life"
          />
        </div>
        <div className="learn-subtitle-section">
          <div className="learn-subtitle">{learnMoreDate.subtitleDate.subtitle}</div>
          <div className="learn-description">{learnMoreDate.subtitleDate.description}</div>
        </div>
      </section>
      <section className="learn-bottom-section">
        <h3 className="learn-bottom-text">{learnMoreDate.lastSection.title}</h3>
        <h3 className="learn-bottom-text">
          <Link to={CATALOG()} className="learn-bottom-link">{learnMoreDate.lastSection.descriptionLink}</Link>
          {learnMoreDate.lastSection.description}
        </h3>
      </section>
    </main>
  )
}
