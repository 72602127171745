import { useContext } from 'react'

import { TQuotaContextType } from '../types'
import { QuotaContext } from '../context/QuotaContext'

export const useQuotaContext = (): TQuotaContextType => {
  const context = useContext(QuotaContext)

  if (!context) {
    throw new Error('QuotaContext must be used within a QuotaContext.Provider')
  }

  return context
}
