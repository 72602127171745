import './index.css'

import { FC, useRef } from 'react'
import { Link } from 'react-router-dom'

import useDragHorizontal from '@hooks/useDragHorizontal'
import { TPopularVehiclesResp, TVehicleCategoriesResp } from '@api/types'
import { CATALOG } from '@helpers/routes'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'

import PopularCars from '../../../servicePages/components/PopularCars/PopularCars'

type TOurWhipssProps = {
  popularVehicles: TPopularVehiclesResp
  ourFleet: TVehicleCategoriesResp
}

export const OurWhipss: FC<TOurWhipssProps> = ({ popularVehicles, ourFleet }) => {
  const categoriesListRef = useRef<HTMLUListElement | null>(null)
  const brandsListRef = useRef<HTMLUListElement | null>(null)

  const dragHandlersForCategories = useDragHorizontal(categoriesListRef)
  const dragHandlersForBrands = useDragHorizontal(brandsListRef)

  return (
    <section
      className="our-whipss"
      id="fleet"
    >
      <div className="whipss-categories">
        <h2 className="our-whipss-title">Our whipss</h2>
        {ourFleet && (
          <>
            <ul
              aria-label="Scrollable list of vehicle categories"
              role="listbox"
              className="whipss-items scrollable-cnt"
              ref={categoriesListRef}
              {...dragHandlersForCategories}
            >
              {ourFleet.categories?.map((category) => (
                <li
                  className="whipss-item"
                  key={category.id}
                  draggable={false}
                >
                  <Link
                    draggable={false}
                    to={URLParamsFormatter.formatPathParams(CATALOG(), {
                      [QueryVoccab.categoryId]: category.id
                    })}
                  >
                    <img
                      className="whipss-img"
                      draggable={false}
                      src={category.src}
                      alt={category.prestige}
                    />
                  </Link>
                  <div className="image-description">{category.prestige}</div>
                </li>
              ))}
            </ul>
            <ul
              aria-label="Scrollable list of vehicle brands"
              role="listbox"
              className="models scrollable-cnt"
              ref={brandsListRef}
              {...dragHandlersForBrands}
            >
              {ourFleet.vehicleBrands?.map((model) => (
                <li
                  key={model.id}
                  draggable={false}
                >
                  <Link
                    className="model-link"
                    draggable={false}
                    to={URLParamsFormatter.formatPathParams(CATALOG(), {
                      car_maker: [model.id]
                      // [model.groupCode]: model.id
                    })}
                  >
                    <img
                      src={model.iconUrl}
                      alt="model"
                      draggable={false}
                      width="40px"
                      height="40px"
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <section className="popular-cars-cnt">
        <p className="popular-title">Hot right now</p>
        <PopularCars popularVehicles={popularVehicles} />
      </section>
    </section>
  )
}
