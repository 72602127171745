import { Section } from '../types'

export const cookieData: Section[] = [
  {
    subsections: [
      {
        content:
          'At Whipss Inc, we use cookies to enable personalized services, even when you\'re not logged into your account. You can update your preferences anytime on this page. For details on how we process data collected through your use of our services, please refer to our Privacy Policy.'
      },
      {
        content:
          'When you interact with our platform, Whipss Inc and its partners utilize various cookies, pixels, tags, and other tracking technologies (collectively referred to as “Cookies”). This Cookies Policy explains the types of Cookies we use, their purposes, and how you can control or limit their use.'
      },
      {
        content:
          'This Cookies Policy is an integral part of our Terms and Conditions of Use and Privacy Policy. Any capitalized terms herein are defined as per the Terms and Conditions of Use and/or the Privacy Policy.'
      },
    ]
  },
  {
    titleLeft: 'What are Cookies?',
    subsections: [
      {
        content: 'Cookies are small text files stored on your computer or cell phone, which store information to operate websites and/or collect information about your browsing.'
      },
      {
        subtitle: 'Types of Cookies Used by Whipss',
        content: 'Whipss uses Cookies to provide you with a seamless and feature-rich experience while optimizing the performance of our Platform and Services. All data collected via Cookies is transmitted securely through encrypted connections.'
      },
      {
        subtitle: 'Functional Cookies',
        content: 'Functional Cookies are critical for the smooth operation of our Platform. They enable essential features that allow you to navigate and use the Website and App effectively, such as:'
      },
      {
        contentDotBox: [
          {
            contentDotSpan: 'Session management: ',
            content: 'Maintaining session IDs and authentication.',
          },
          {
            contentDotSpan: 'Security: ',
            content: 'Ensuring a secure browsing experience.',
          },
          {
            contentDotSpan: 'Preferences: ',
            content: 'Storing language settings and user choices',
          },
          {
            contentDotSpan: 'Convenience: ',
            content: ' Remembering your vehicle selections, finalizing bookings, and preventing repetitive data entry.',
          }
        ]
      },
      {
        content: 'In addition, these Cookies:'
      },
      {
        contentDotBox: [
          {
            contentDot: 'Save your preferences for Cookie storage.'
          },
          {
            contentDot: 'Measure audience activity solely to ensure uninterrupted service'
          },
          {
            contentDot: 'Identify and resolve browsing issues.'
          },
          {
            contentDot: 'Estimate server capacity and optimize platform performance.'
          }
        ]
      },
      {
        content: 'All functional Cookies are exclusive to Whipss and cannot be disabled as they are necessary for delivering our services.'
      },
      {
        content: 'Functional Cookies Used by Whipss:'
      },
      {
        contentDotBox: [
          {
            contentDotSpan: '_session_id: ',
            content: 'Supports navigation for logged-in users.',
          },
          {
            contentDotSpan: 'user_reference: ',
            content: 'Retains vehicle selections for non-logged-in users.',
          },
          {
            contentDotSpan: 'ab_cookie: ',
            content: 'Ensures content consistency during A/B testing.',
          }
        ]
      },
      {
        content: 'These Cookies are automatically activated during your use of the Website or App and cannot be deleted or disabled due to their essential role in service delivery.'
      },
      {
        subtitle: 'Non-Functional Cookies',
        content: 'The use of non-functional Cookies requires your explicit consent. By accepting these Cookies, you allow Whipss and its partners to process your Personal Data for the purposes outlined below.'
      },
      {
        subtitle: 'Audience Analysis Cookies',
        content: 'Audience analysis Cookies, powered by the "Whipss Capture" Cookie, allow us to link your Platform activity to your account for a more detailed understanding of your interactions. By accepting these Cookies, you help us refine our Services to better suit your needs.'
      },
      {
        subtitle: 'Performance Cookies',
        content: 'Performance Cookies gather insights into how you use our Website, helping us enhance its functionality and user experience. These Cookies enable us to:'
      },
      {
        contentDotBox: [
          {
            contentDot: 'Compile traffic statistics and analyze the use of different Website components to improve the relevance and usability of our Services.'
          },
          {
            contentDot: 'Monitor Website performance, including pages visited, time spent, and actions taken (e.g., clicks, scrolling).'
          },
          {
            contentDot: 'Test new features to ensure an optimized user experience.'
          }
        ]
      },
      {
        subtitle: 'Advertising Personalization and Improvement Cookies',
        content: 'Advertising personalization and improvement Cookies optimize ads related to our Platform, whether they originate from Whipss or third parties. These Cookies allow us to:'
      },
      {
        contentDotBox: [
          {
            contentDot: 'Deliver ads tailored to your profile and preferences, avoiding irrelevant content.'
          },
          {
            contentDot: 'Monitor the performance of our ads to improve their effectiveness, such as tracking actions taken after viewing or interacting with our ads.'
          }
        ]
      },
      {
        content: 'Blocking these Cookies may result in less relevant content and ads displayed by Whipss or on third-party websites.'
      },
    ]
  },
  {
    titleLeft: 'Our partners',
    subsections: [
      {
        content: 'The following Whipss partners store Cookies on your device and can therefore use your data. For more information, the links to their privacy policies are set out below:'
      },
      {
        subtitle: 'Performance cookies'
      },
      {
        contentDotBox: [
          {
            contentDot: '“Hotjar” operated by Hotjar Ltd, Dragonara Business center 5th Floor, Dragonara Road, Paceville St Julian’s STJ 3141, Malta: ',
            link: 'https://www.hotjar.com/legal/policies/privacy/'
          }
        ]
      },
      {
        subtitle: 'Advertising personalization and improvement cookies'
      },
      {
        contentDotBox: [
          {
            contentDot: 'Facebook Custom Audience, operated by Meta Platforms Ireland Limited FAO: Privacy Operations Merrion Road Dublin 4 D04 X2K5, Ireland: ',
            link: 'https://www.facebook.com/privacy/policy'
          },
          {
            contentDot: '“Google AdWords Conversion”, “Google Dynamic Remarketing”, “Google Double Click” and “Google Analytics 4” operated by Google, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA: ',
            link: 'https://business.safety.google/privacy/'
          },
          {
            contentDot: '“Cikneeto”, “Cikneeto_last_ad” and “Cikneeto_uuid”, operated by Clickon (trade name Admo.tv), 11 rue Garnier, 92200, Neuilly sur Seine: ',
            link: 'https://www.admo.tv/en/privacy-policy'
          },
          {
            contentDot: '“Tiktok Pixel” operated by TikTok Information Technologies UK Limited, Kaleidoscope, 4 Lindsey Street, London, EC1A 9HP, United Kingdom, ',
            link: 'https://www.tiktok.com/legal/page/us/privacy-policy/en'
          },
          {
            contentDot: '“Snap Pixel” operated by Snap Inc., 3000 31st Street, Santa Monica, California 90405, ',
            link: 'https://snap.com/en-GB/privacy/privacy-policy'
          },
          {
            contentDot: '“Bing Ads” operated by Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, Ireland: ',
            link: 'https://snap.com/en-GB/privacy/privacy-policy'
          },
          {
            contentDot: '“Adjust” operated by Ajdust GmbH, Saarbrücker Str. 37a, 10405 Berlin, Germany ',
            link: 'https://www.adjust.com/terms/privacy-policy'
          }
        ]
      }
    ]
  },
  {
    titleLeft: 'Managing Your Cookie Settings',
    subsections: [
      {
        subtitle: 'Providing Consent',
        content: 'When you visit our Platform, a banner will appear asking for your consent to use certain Cookies. These Cookies will only be activated once you provide consent. You can configure your preferences for all Cookies except for functional ones.'
      },
      {
        subtitle: 'Customizing or Withdrawing Consent'
      },
      {
        subtitle: 'Managing Cookies via Our Interface',
        content: 'Managing Cookies via Our Interface'
      },
      {
        subtitle: 'Managing Cookies via an Interprofessional Platform',
        content: 'To disable certain advertising Cookies placed by our partners, visit Your Online Choices.'
      },
      {
        subtitle: 'Managing Cookies via Your Web Browser',
        content: 'Most web browsers allow you to control and delete third-party Cookies. Specific instructions for major browsers can be found on their official sites:'
      },
      {
        contentDotBox: [
          {
            contentDot: 'Microsoft Edge'
          },
          {
            contentDot: 'Google Chrome'
          },
          {
            contentDot: 'Apple Safari'
          },
          {
            contentDot: 'Mozilla Firefox'
          }
        ]
      },
      {
        subtitle: 'Managing Cookies on Your Smartphone',
        content: 'You can configure your Cookie preferences directly through your smartphone’s operating system settings.'
      },
      {
        subtitle: 'Important Notes',
        content: 'Restricting certain Cookies may impact the functionality of our Website and App, as well as the quality of the Services we provide.'
      },
      {
        subtitle: 'Retention of Cookie Choices and Data',
      },
      {
        contentDotBox: [
          {
            contentDot: 'Your preferences regarding Cookie acceptance or refusal will be retained for 12 months.'
          },
          {
            contentDot: 'Cookies themselves will be stored for no more than 13 months.'
          }
        ]
      }
    ]
  }
]
