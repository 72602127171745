import { FC, ReactNode } from 'react'

import './index.css'

type AccordionProps = {
  summary: string
  children: ReactNode
}

export const Accordion: FC<AccordionProps> = ({ summary, children }) => (
  <details>
    <summary>{summary}</summary>
    <div>{children}</div>
  </details>
)
