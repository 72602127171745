import './index.css'

import PopularCars from '../PopularCars/PopularCars'

export const FindAnyCar = () => {
  return (
    <section className="find-car-main-cnt">
      <div className="find-car-text-cnt">
        <h3 className="find-car-title">Sorry, we couldn’t find the car you were looking for</h3>
        <p className="find-car-about">We have other whipss you will like, choose from the list below</p>
      </div>
      <PopularCars renderLimit={3} />
    </section>
  )
}
