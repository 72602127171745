import thankCarImg from './assets/thankCarImg.webp'

export const mainTitle = 'Finalizing reservation'
export const description = 'Getting the last few details together to get the final price'
export const content = {
  protectionPlan: {
    subtitle: 'Choose a protection plan to limit your liability in case anything goes wrong'
  },
  extrasOptions: {
    subtitle: 'Choose the extras you want for the trip to maximize comfort'
  },
  driversLicense: {
    subtitle:
      'Include your contact information and a picture of your Driver’s License so we can send you your Trip details',
    dlUploadReason:
      "Uploading your driver's license allows us to verify your identity and confirm the validity of the document. This helps ensure security and compliance with all legal requirements, as well as prevent fraud. Your data will be protected and used only for the purposes specified.",
    modalSubtitle: "Only a photo of the front side of the driver's license"
  }
}
export const uploadText =
  'Upload a picture of your Insurance Card or Declaration Page with the Company Name and Policy Number'

export const protectionPlanStep = 'Protection Plan'
export const extrasOptionsStep = 'Extras'
export const driversLicenseStep = 'Driver’s License'

export const uploadButtonBody = 'Upload'
export const uploadTitleBody = 'Uploaded *'

export const uploadProtectionPlanSubtitle =
  'Upload a picture of your Insurance Card or Declaration Page with the Company Name and Policy Number'

export const uploadContainerTitle = 'Drag & drop files or Browse'
export const uploadContainerAbout = 'Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT'

export const uploadButton = 'Upload Files'

export const ThirdStepDescription =
  "Uploading your driver's license allows us to verify your identity and confirm the validity of the document. This helps ensure security and compliance with all legal requirements, as well as prevent fraud. Your data will be protected and used only for the purposes specified."
export const driversLicenseSubtitle = "Only a photo of the front side of the driver's license"

export const confirmationData = {
  title:
    'Congrats, we got your information and currently finalizing your quote, please give us 5-10 minutes to send you a confirmation to your email '
}
export const customProtectionPlanDescription =
  'Use your own insurance policy to minimize risk during your trip'
