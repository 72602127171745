import './index.css'

import { useState, useMemo, FC, ChangeEvent } from 'react'

import { Search } from '@shared/icons'
import { Accordion } from '@shared/components'
import { TFilterTypes, TValue } from '@api/types'

import { TDynamicFilterParams } from '../Catalogue/Filters/FiltersGroup'

type TMultiSelectFilter = {
  options: TValue[]
  defaultValue?: number[] | undefined
  filterType: TFilterTypes
  label: string
  name: string
  handleChange: (params: TDynamicFilterParams) => void
  isSearchable?: boolean
}

type TSearchField = {
  searchTerm: string
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const SearchField: FC<TSearchField> = ({ searchTerm, handleSearchChange }) => (
  <div className="search-container">
    <input
      type="text"
      placeholder="Search"
      value={searchTerm}
      onChange={handleSearchChange}
      className="search-input"
    />
    <div className="search-icon">
      <Search />
    </div>
  </div>
)

const MultiSelectFilter: FC<TMultiSelectFilter> = ({
  options,
  defaultValue,
  filterType,
  label,
  name,
  handleChange,
  isSearchable
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedValues, setSelectedValues] = useState<number[]>(
    Array.isArray(defaultValue) ? defaultValue : []
  )

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)

  const filteredOptions = useMemo(
    () => options.filter((option) => option.name.includes(searchTerm)),
    [options, searchTerm]
  )

  const handleCheckboxChange = (id: number) => {
    const updatedSelectedValues = selectedValues.includes(id)
      ? selectedValues.filter((value) => value !== id)
      : [...selectedValues, id]

    setSelectedValues(updatedSelectedValues)

    handleChange({
      fieldName: name,
      selectedValues: updatedSelectedValues,
      type: filterType
    })
  }

  return (
    <Accordion summary={label}>
      <div className="multi-select-container">
        {isSearchable && (
          <SearchField
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
          />
        )}
        {filteredOptions.map((option) => (
          <label
            key={option.name + option.id}
            className="checkbox-label"
          >
            <input
              type="checkbox"
              checked={selectedValues.includes(option.id)}
              onChange={() => handleCheckboxChange(option.id)}
              className="custom-checkbox"
            />
            <span className="checkbox-text">{option.name}</span>
          </label>
        ))}
      </div>
    </Accordion>
  )
}

export default MultiSelectFilter
