import './index.css'

import { FC } from 'react'

import { TQuotaResp } from '@api/types/quota'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { CATALOG, HOME } from '@helpers/routes'

import { ThankCar } from '../../components/ThankCar/ThankCar'
import thankCheck from '../../assets/thankCheck.webp'
import thankAction from '../../assets/thankAction.webp'
import { confirmationData } from '../../data'
import { FormValues } from '../../types'

type ThankYouProps = {
  clientFormData: FormValues | null
  quotaDetails: TQuotaResp | null
}

export const ThankYou: FC<ThankYouProps> = ({ clientFormData, quotaDetails }) => {
  return (
    <main className="thank-wrp">
      <section className="thank-info-cnt">
        <img
          className="thank-info-img"
          src={thankCheck}
          alt="thankCheckIcon"
        />
        <p className="thank-info-description">
          {confirmationData.title}
          <span className="thank-info-span">{clientFormData?.email}</span>
        </p>
      </section>

      <section className="thank-content">
        <ThankCar />
        <div className="thank-summary-wrp">
          <div className="thank-summary-item">
            <h5 className="thank-summary-info">Protection Plan Selected:</h5>
            <p className="thank-summary-about">
              {quotaDetails?.protectionPlan?.title} - {quotaDetails?.protectionPlan?.price}
            </p>
          </div>
          {quotaDetails?.extras.length > 0 && (
            <div className="thank-summary-item">
              <h5 className="thank-summary-info">Extras Selected</h5>
              {quotaDetails?.extras.map((extra, index) => (
                <p
                  className="thank-summary-about"
                  key={index}
                >
                  {extra.name}
                </p>
              ))}
            </div>
          )}
          <div className="thank-summary-item">
            <h5 className="thank-summary-info">Email Submitted</h5>
            <p className="thank-summary-about">{clientFormData?.email}</p>
          </div>
          <div className="thank-summary-item">
            <h5 className="thank-summary-info">Phone Number Submitted</h5>
            <p className="thank-summary-about">{clientFormData?.phone}</p>
          </div>
        </div>

        <div className="thank-price-wrp">
          <ul className="thank-price-ul">
            {quotaDetails?.price?.options.map((option, index) => (
              <li
                className="thank-price-li"
                key={index}
              >
                <p className="thank-price-li-text">{option.name}</p>
                <p className="thank-price-li-text">{option.total}</p>
              </li>
            ))}
          </ul>
          <div className="thank-price-sum-cnt">
            <p className="thank-price-sum-text">Total</p>
            <p className="thank-price-sum-text">${quotaDetails?.price?.total}</p>
          </div>
        </div>

        <div className="thank-action-wrp">
          <img
            className="thank-action-img"
            src={thankAction}
            alt="thankActionImg"
          />
          <div className="thank-action-cnt">
            <LinkButton
              to={CATALOG()}
              type="secondary"
              name="Return to cars"
            />
            <LinkButton
              to={HOME}
              type="secondary"
              name="Back to home"
            />
          </div>
        </div>
      </section>
    </main>
  )
}
