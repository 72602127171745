import { Dispatch, FC, SetStateAction } from 'react'
import './index.css'

import { Delete } from '@shared/icons'
import { useFileUpload } from '@hooks/useFileUpload'

import { UploadSection } from '../../components/UploadSection/UploadSection'
import {
  uploadButtonBody, uploadProtectionPlanSubtitle, uploadText
} from '../../data'

export const CUSTOM_PROTECTION_PLAN = {
  id: 0,
  title: 'Own Insurance',
  description: 'Use your own insurance policy to minimize risk during your trip',
  price: null,
  details: [],
  type: '',
  recommended: false
}

type TCustomProtectionPlanDetailsProps = {
  protectionPlanFile: File | null
  setProtectionPlanFile: Dispatch<SetStateAction<File | null>>
  isUploadModalVisible: boolean
  setIsUploadModalVisible: Dispatch<SetStateAction<boolean>>
}

export const CustomProtectionPlanDetails: FC<TCustomProtectionPlanDetailsProps> = ({
  protectionPlanFile,
  setProtectionPlanFile,
  isUploadModalVisible,
  setIsUploadModalVisible
}) => {
  const { handleFileChange } = useFileUpload(setProtectionPlanFile)

  return (
    <div className="protectionBody-container">
      <div className="protectionBody-item-upload">
        <div className="protectionBody-upload-text">{uploadText}</div>
        {!protectionPlanFile && (
          <button
            className="protectionBody-upload-button"
            onClick={() => setIsUploadModalVisible(true)}
          >
            {uploadButtonBody}
          </button>
        )}
        <UploadSection
          subtitle={uploadProtectionPlanSubtitle}
          setUploadedFile={setProtectionPlanFile}
          uploadedFile={protectionPlanFile}
          onCloseUploadModal={() => setIsUploadModalVisible(false)}
          handleFileChange={handleFileChange}
          isUploadModalVisible={isUploadModalVisible}
        />
        {protectionPlanFile && (
          <>
            <label className="styled-label">Uploaded*</label>
            <div className="uploaded-file">
              <input
                value={protectionPlanFile.name}
                readOnly
              />
              <Delete callback={() => setProtectionPlanFile(null)} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
