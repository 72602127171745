import './index.css'

import { FC, memo } from 'react'
import { Fade, Modal } from '@mui/material'

import { Button, ResponsiveImage } from '@shared/components'
import { Close } from '@shared/icons'
import PickupReturnInfo from '@shared/components/SelectLocation/PickupReturnInfo'
import { TCalculatePriceRes } from '@api/types/calculate_price'
import { TVehicle } from '@api/types/vehicles'

import { useGlobalContext } from '../../../../GlobalContext'

import PriceDetails from './PriceDetails'

type TPriceDetailsModal = {
  id: string
  isOpen: boolean
  priceData: TCalculatePriceRes
  onClose: () => void
}

export const PriceDetailsModal: FC<TPriceDetailsModal> = memo(
  ({
    id, isOpen, onClose, priceData
  }) => {
    const { getPotentialVehicle } = useGlobalContext()
    const vehicle: TVehicle = getPotentialVehicle(Number(id))
    return (
      <Modal
        open={isOpen}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={isOpen}>
          <article className="price-modal-container">
            <header className="price-modal-header">
              {vehicle && (
                <ResponsiveImage
                  urls={vehicle.images[0]}
                  alt={`Exterior view of ${vehicle.details.model}`}
                />
              )}
              <button
                className="close-wrapper"
                onClick={onClose}
              >
                <Close />
              </button>
            </header>
            <section className="price-modal-content">
              {vehicle && (
                <h5 className="car-name">
                  {vehicle.details.maker}&nbsp;{vehicle.details.model}
                </h5>
              )}
              <PickupReturnInfo />
              <PriceDetails priceData={priceData} />
              <Button
                onClick={onClose}
                name="Done"
              />
            </section>
          </article>
        </Fade>
      </Modal>
    )
  },
  (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
)
