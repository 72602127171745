import { TPlan } from '@api/types'
import { CheckFilled, Dismiss, Info } from '@shared/icons'

import { FC } from 'react'

type TProtectionPlanDetailsProps = {
  protectionCard: TPlan
}

export const ProtectionPlanDetails: FC<TProtectionPlanDetailsProps> = ({ protectionCard }) => {
  return (
    <div className="protectionBody-container">
      {protectionCard.details.map((option, index: number) => (
        <div
          className="protectionBody-item"
          key={index}
        >
          <div className="protectionBody-option-container">
            {option.isIncluded ? (
              <CheckFilled
                color="#FFF"
                width="20"
                height="20"
              />
            ) : (
              <Dismiss />
            )}
            <div className={`protectionBody-option-about ${option.isIncluded ? 'checked' : ''}`}>
              {option.title}
            </div>
          </div>
          <Info
            width="20"
            height="20"
          />
        </div>
      ))}
    </div>
  )
}
