import './index.css'
import { Link } from 'react-router-dom'

import dayjsExtended from '@helpers/dayjsExtended'
import { URLParamsFormatter, QueryVoccab } from '@helpers/queryFormatter'
import { ABOUT_US, CATALOG, HOME_FAQ_ANCHOR } from '@helpers/routes'
import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@helpers/constants'

import { BurgerMenu, CloseMenu } from '../../icons'
import LinkButton from '../LinkButton/LinkButton'

type TMenu = {
  openMenu: boolean
  handleToggleMenu: () => void
}

export const Menu = ({ openMenu, handleToggleMenu }: TMenu) => {
  return (
    <>
      <BurgerMenu
        onClick={handleToggleMenu}
        className="header-menu"
      />
      {openMenu && (
        <div
          className="menu-wrp"
          open={openMenu}
          onClose={handleToggleMenu}
        >
          <CloseMenu
            onClick={handleToggleMenu}
            className="menu-close"
          />
          <ul className="menu-navigation">
            <li>
              <Link
                to={ABOUT_US}
                className="menu-nav-item"
                onClick={handleToggleMenu}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to={HOME_FAQ_ANCHOR}
                className="menu-nav-item"
                onClick={handleToggleMenu}
              >
                FAQ
              </Link>
            </li>
            <div className="menu-button-cars">
              <LinkButton
                name="Cars available now"
                // onClick={handleToggleMenu}
                to={URLParamsFormatter.formatPathParams(CATALOG(), {
                  [QueryVoccab.dropOffTime]: dayjsExtended
                    .utc()
                    .add(AVAILABLE_NOW_MIN_RENT_DAYS, 'days')
                    .format()
                })}
              />
            </div>
          </ul>
        </div>
      )}
    </>
  )
}
