import { Base } from './Base'
import { page404Date } from '../../data'
import './index.css'

export const Page404 = () => {
  return (
    <Base
      backgroundClass="page404-backImg"
      cntClass="page404-cnt"
      title={page404Date.title}
      about={page404Date.about}
      button={page404Date.button}
    />
  )
}
