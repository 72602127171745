import './index.css'

import { FC } from 'react'
import Avatar from '@mui/material/Avatar'

import { CommonRating, Box } from '@shared/components'
import { TCarHost } from '@api/types'

type HostBlockProps = {
  host: TCarHost
}

export const HostBlock: FC<HostBlockProps> = ({ host }) => {
  return (
    <>
      <h4 className="host-title">Host</h4>
      <div className="host-info">
        <Avatar
          alt="Avatar"
          src={host?.avatar}
          sx={{ width: 80, height: 80 }}
        />
        <div className="host-details">
          <p className="host-name">{host?.name}</p>
          <CommonRating value={4.5} />
          <Box gap="16px">
            <p className="host-detail">{host?.tripsCount}</p>
            <p className="host-detail">Joined&nbsp;{host?.joinedDate}</p>
          </Box>
        </div>
      </div>
      <p className="host-description">{host?.description}</p>
    </>
  )
}
