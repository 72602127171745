import { FC, useState, useRef } from 'react'
import Slider from '@mui/joy/Slider'

import { Accordion } from '@shared/components'
import { throttle } from '@helpers/helpers'
import { TFilterTypes } from '@api/types'
import CurrencyFormatter from '@helpers/currencyFormatter'

import { TDynamicFilterParams } from '../Catalogue/Filters/FiltersGroup'

type TRangeFilter = {
  options: [number, number]
  label: string
  name: string
  filterType: TFilterTypes
  handleChange: (params: TDynamicFilterParams) => void
  defaultValue?: [number, number] | undefined
}

const RangeFilter: FC<TRangeFilter> = ({
  label, name, options, defaultValue, handleChange, filterType
}) => {
  const [min, max] = options
  const [value, setValue] = useState<number[]>(Array.isArray(defaultValue) ? defaultValue : [min, max])

  const throttledHandleChange = useRef(throttle(handleChange, 1000)).current

  const handleSliderChange = (_event: Event, updatedSelectedValues: number[]) => {
    setValue(updatedSelectedValues)
    throttledHandleChange({
      fielname: name,
      selectedValues: updatedSelectedValues,
      type: filterType
    })
  }

  const marks = [
    {
      value: min,
      label:
        name === 'price_per_day'
          ? CurrencyFormatter.format({
            amount: min
          })
          : min
    },
    {
      value: max,
      label:
        name === 'price_per_day'
          ? CurrencyFormatter.format({
            amount: max
          })
          : max
    }
  ]

  return (
    <Accordion summary={label}>
      <div
        style={{
          padding: '36px'
        }}
      >
        <Slider
          value={value}
          onChange={handleSliderChange}
          valueLabelDisplay="on"
          min={min}
          max={max}
          marks={marks}
          sx={{
            '& .MuiSlider-thumb': {
              backgroundColor: '#EEE',
              '&:hover': {
                backgroundColor: '#EEE'
              },
              '&:before': {
                border: 'none'
              }
            },
            '& .MuiSlider-track': {
              backgroundColor: '#FFF'
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#484848'
            }
          }}
        />
      </div>
    </Accordion>
  )
}

export default RangeFilter
