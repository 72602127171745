import './index.css'

import { FC } from 'react'

import { AutomaticTransmission, ManualTransmission, Passenger, Luggage } from '@shared/icons'
import { TCarVehicleDetails } from '@api/types'

type TVehicleDetailsProps = {
  details: TCarVehicleDetails
  size?: string
  transmission?: string
  seats?: string
  places?: string
}

export const VehicleDetails: FC<TVehicleDetailsProps> = ({
  details,
  transmission = '',
  seats = '',
  places = '',
  size = '16'
}) => {
  const TransmissionIcon = details?.transmission === 'manual' ? ManualTransmission : AutomaticTransmission

  return (
    <div className="vehicle-details">
      <div className="vehicle-attributes">
        {details?.transmission && (
          <div className="vehicle-attribute">
            <TransmissionIcon
              width={size}
              height={size}
            />
            {transmission}
          </div>
        )}
        {details?.passengers && (
          <div className="vehicle-attribute">
            <Passenger
              width={size}
              height={size}
            />
            &nbsp;
            <span className="count">
              {details?.passengers} {seats}&nbsp;
            </span>
          </div>
        )}
        {details?.luggage && (
          <div className="vehicle-attribute">
            <Luggage
              width={size}
              height={size}
            />
            &nbsp;
            <span className="count">
              {details?.luggage} {places}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
