import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'

export const Base = ({
  title,
  subtitle,
  about,
  button,
  backgroundClass,
  cntClass,
  subtitleClass,
  aboutClass
}) => {
  return (
    <>
      <div className={backgroundClass}></div>
      <section className={`error-main-cnt ${cntClass}`}>
        <div className="error-text-cnt">
          <h3 className="error-title">{title}</h3>
          <div className="error-description">
            <h5 className={subtitleClass}>{subtitle}</h5>
            <p className={`error-about ${aboutClass}`}>{about}</p>
          </div>
        </div>
        <Link
          to="/"
          className="error-button"
        >
          {button}
        </Link>
      </section>
    </>
  )
}
