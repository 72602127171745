import queryString from 'query-string'

import { TLocation } from '@api/types'

import dayjsExtended, { Dayjs } from './dayjsExtended'

export type TParseValue = string | null | number | Array<string | null | number | boolean> | undefined
type TParsedQuery = Partial<Record<QueryVoccab, TParseValue>>

export enum QueryVoccab {
  'pickUpTime' = 'pt',
  'dropOffTime' = 'dt',
  // location accoring to /locations
  'pickUpLocationId' = 'plid',
  'pickUpLocationName' = 'pn',
  'pickUpLocationType' = 'pl',
  'dropOffLocationName' = 'dn',
  'dropOffLocationType' = 'dl',
  'dropOffLocationId' = 'dlid',
  'rentDays' = 'rd',
  'filters' = 'f',
  'sort' = 'st',
  'isDifferentReturnLocation' = 'isdrl',
  // @INFO: fields related to /categories && /popular routes
  'categoryId' = 'cid',
  'groupCode' = 'gc'
}

export enum QuerySuffixVoccab {
  'filterType' = '_f_t' // @INFO: field type for /search request
}

class ParamsFormatter {
  private readonly arrayFormatOptions = {
    arrayFormat: 'bracket-separator' as const,
    arrayFormatSeparator: '|'
  }

  private parsedURL(): TParsedQuery {
    const search = window.location.search
    return queryString.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
      ...this.arrayFormatOptions
    })
  }

  public getParam(value: QueryVoccab | string): TParseValue {
    const parsed = this.parsedURL()
    return parsed[value] ?? null
  }

  public getParams(): TParsedQuery {
    return this.parsedURL()
  }

  public getTimeParam(value: QueryVoccab): Dayjs {
    const param = this.getParam(value)
    return param ? dayjsExtended.utc(param) : dayjsExtended.utc()
  }

  public getQueryLocations(): Record<'pickUpLocation' | 'returnLocation', TLocation | null> {
    const params = this.getParams()

    const pickUpLocation = params[QueryVoccab.pickUpLocationId]
      ? {
        id: params[QueryVoccab.pickUpLocationId],
        name: params[QueryVoccab.pickUpLocationName],
        type: params[QueryVoccab.pickUpLocationType],
        label: params[QueryVoccab.pickUpLocationName]
      }
      : null

    const returnLocation = params[QueryVoccab.dropOffLocationId]
      ? {
        id: params[QueryVoccab.dropOffLocationId],
        name: params[QueryVoccab.dropOffLocationName],
        type: params[QueryVoccab.dropOffLocationType],
        label: params[QueryVoccab.dropOffLocationName]
      }
      : null

    return {
      pickUpLocation,
      returnLocation
    }
  }

  public getDynamicObjectParam(storeKey: QueryVoccab): Record<string, TParseValue> {
    const params = this.getParams()
    const keysToInclude = (params[storeKey] as string[]) ?? []
    const filteredParams: Record<string, TParseValue> = {}

    for (const key of keysToInclude) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filteredParams[key] = params[key]
      }
    }

    return filteredParams
  }

  public formatTimeParam = (key: QueryVoccab, value: Dayjs): string => {
    return this.formatParam(key, value.format())
  }

  public formatParam(key: QueryVoccab, value: TParseValue): string {
    const parsed = this.parsedURL()
    parsed[key] = value

    return queryString.stringify(parsed, this.arrayFormatOptions)
  }

  public formatParams(setParams?: Partial<Record<QueryVoccab, unknown>>): string {
    const parsed = this.parsedURL()

    return queryString.stringify(
      {
        ...parsed,
        ...setParams
      },
      this.arrayFormatOptions
    )
  }

  public formatDynamicObjectParams = ({
    storeKey,
    dynamicKey,
    value,
    other = {}
  }: {
    storeKey: QueryVoccab
    dynamicKey: string
    value: TParseValue
    other?: Record<string, TParseValue>
  }): string => {
    const getAppliedFilterNames = (): string[] => {
      const appliedFilterNames = this.getParam(storeKey)

      if (appliedFilterNames && Array.isArray(appliedFilterNames)) {
        const uniqueValues = new Set([...appliedFilterNames, dynamicKey])
        return Array.from(uniqueValues) as string[]
      }

      return [dynamicKey]
    }

    return this.formatParams({
      [dynamicKey]: value,
      [storeKey]: getAppliedFilterNames(),
      ...other
    })
  }

  // @INFO: build example -> multiple_ft: multiple
  public formatDynamicObjectServiceField = (key: string, suffix: QuerySuffix) => {
    return `${key}${suffix}`
  }

  public formatPathParams = (path: string, params?: Partial<Record<QueryVoccab, unknown>>) => {
    const formattedParams = this.formatParams(params)
    return `${path}?${formattedParams}`
  }
}

export const URLParamsFormatter = new ParamsFormatter()
