import './index.css'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { FC, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import dayjs, { Dayjs as TDayjs } from 'dayjs'

import { useGet } from '@hooks/useApi'
import { TLocation, TLocationsResp } from '@api/types'
import { QueryVoccab, URLParamsFormatter } from '@helpers/queryFormatter'

import { Button } from '../Button/Button'

import { DifferentReturnLocation } from './DifferentReturnLocation'

type TSearchParams = {
  pickUpLocation: number | null
  returnLocation: number | null
  pickUpTime: string | null
  dropOffTime: string | null
}

type TSelectPickupAndReturn = {
  onSearch: (value: TSearchParams) => void
  padding?: string
  isSearchDisabled?: boolean
  setIsModalOpen?: (value: boolean) => void
  searchButtonName?: string
  afterChildren?: JSX.Element
  locations?: TLocationsResp
}

export const SelectPickupAndReturn: FC<TSelectPickupAndReturn> = ({
  onSearch,
  padding = '24px',
  searchButtonName,
  locations,
  isSearchDisabled = false,
  afterChildren = null
}) => {
  const [, setSearchParams] = useSearchParams()
  const params = URLParamsFormatter.getParams()
  const { pickUpLocation, returnLocation } = URLParamsFormatter.getQueryLocations()

  const [isReturnSelected, setDifferentReturn] = useState(params[QueryVoccab.isDifferentReturnLocation])
  const dateNow = dayjs()

  const pickupLocationRef = useRef<TLocation | null>(pickUpLocation)
  const returnLocationRef = useRef<TLocation | null>(returnLocation)
  const pickUpTimeRef = useRef<TDayjs>(URLParamsFormatter.getTimeParam(QueryVoccab.pickUpTime))
  const dropOffTimeRef = useRef<TDayjs>(
    !URLParamsFormatter.getParam(QueryVoccab.dropOffTime)
      ? URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime).add(2, 'day')
      : URLParamsFormatter.getTimeParam(QueryVoccab.dropOffTime)
  )

  const locationsList = locations
    ? {
      data: locations
    }
    : useGet<TLocationsResp>({
      url: '/locations'
    })

  const handleSelectLocations = (paramName: 'pick' | 'drop', location: TLocation | null) => {
    if (location) {
      let URLParams = {}
      if (paramName === 'pick') {
        pickupLocationRef.current = location
        URLParams = {
          [QueryVoccab.pickUpLocationName]: location.name,
          [QueryVoccab.pickUpLocationId]: location.id,
          [QueryVoccab.pickUpLocationType]: location.type
        }
      } else if (paramName === 'drop') {
        returnLocationRef.current = location
        URLParams = {
          [QueryVoccab.dropOffLocationName]: location.name,
          [QueryVoccab.dropOffLocationId]: location.id,
          [QueryVoccab.dropOffLocationType]: location.type,
          [QueryVoccab.isDifferentReturnLocation]: true
        }
      }

      setSearchParams(URLParamsFormatter.formatParams(URLParams))
    }
  }

  const handleSelectDates = (paramName: QueryVoccab, date: TDayjs | null) => {
    if (date) {
      if (paramName === QueryVoccab.pickUpTime) {
        pickUpTimeRef.current = date
      } else if (paramName === QueryVoccab.dropOffTime) {
        dropOffTimeRef.current = date
      }
      setSearchParams(URLParamsFormatter.formatTimeParam(paramName, date))
    }
  }

  const handleSearch = () => {
    if (!URLParamsFormatter.getParam(QueryVoccab.pickUpTime)) {
      setSearchParams(URLParamsFormatter.formatTimeParam(QueryVoccab.pickUpTime, pickUpTimeRef.current))
    }
    if (!URLParamsFormatter.getParam(QueryVoccab.dropOffTime)) {
      setSearchParams(URLParamsFormatter.formatTimeParam(QueryVoccab.dropOffTime, dropOffTimeRef.current))
    }

    const searchParams: TSearchParams = {
      pickUpLocation: pickupLocationRef.current?.id ?? null,
      pickUpTime: pickUpTimeRef.current?.format(),
      returnLocation: returnLocationRef.current?.id ?? null,
      dropOffTime: dropOffTimeRef.current?.format()
    }

    onSearch(searchParams)
  }

  const handleToggleReturnLocation = () => {
    if (isReturnSelected && pickupLocationRef.current) {
      returnLocationRef.current = {
        ...pickupLocationRef.current
      }
      const URLParams = {
        [QueryVoccab.dropOffLocationName]: pickupLocationRef.current.name,
        [QueryVoccab.dropOffLocationId]: pickupLocationRef.current.id,
        [QueryVoccab.dropOffLocationType]: pickupLocationRef.current.type,
        [QueryVoccab.isDifferentReturnLocation]: false
      }
      setSearchParams(URLParamsFormatter.formatParams(URLParams))
    }
    setDifferentReturn(!isReturnSelected)
  }

  return (
    <div
      className="wrapper-select-pickup"
      style={{
        padding
      }}
    >
      <Autocomplete
        options={locationsList.data?.pickUpLocations
          .filter((option) => option.type !== 'guest')
          .map((option) => ({
            label: option.name,
            ...option
          }))}
        value={pickupLocationRef.current}
        loading={locationsList.isLoading}
        onChange={(e, value: TLocation | null) => {
          handleSelectLocations('pick', value)
        }}
        disablePortal
        renderInput={(params) => (
          <TextField
            {...params}
            label="Pick-up location"
          // helperText={!pickupLocationRef.current ? 'Select pickup location' : ''}
          />
        )}
      />
      <>
        <DifferentReturnLocation
          padding="10px 0"
          callback={handleToggleReturnLocation}
          isReturnSelected={isReturnSelected}
        />
        {isReturnSelected && (
          <Autocomplete
            options={locationsList.data?.returnLocations
              .filter((option) => option.type !== 'guest')
              .map((option) => ({
                label: option.name,
                ...option
              }))}
            value={returnLocationRef.current}
            loading={locationsList.isLoading}
            onChange={(e, value: TLocation | null) => {
              handleSelectLocations('drop', value)
            }}
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                label="Return location"
              />
            )}
          />
        )}
      </>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="Pick-up Date and Time"
          value={pickUpTimeRef.current}
          minDate={dateNow}
          onChange={(value) => handleSelectDates(QueryVoccab.pickUpTime, value)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock
          }}
        />
        <DateTimePicker
          label="Return Date and Time"
          value={dropOffTimeRef.current}
          minDate={dateNow.add(params[QueryVoccab.rentDays] ?? 2, 'day').endOf('day')}
          onChange={(value) => handleSelectDates(QueryVoccab.dropOffTime, value)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock
          }}
        />
      </LocalizationProvider>
      <Button
        size="large"
        disabled={isSearchDisabled}
        name={searchButtonName ?? 'Search'}
        onClick={handleSearch}
        showIcon={!searchButtonName}
      />
      {afterChildren}
    </div>
  )
}
