import './index.css'
import { NavLink, Outlet } from 'react-router-dom'
import cn from 'classnames'

export const LegalMatters = () => {
  return (
    <div className="legal-matters">
      <header className="legal-header">
        <h1 className="legal-main-title">Legal Matters</h1>
        <nav className="legal-tabs">
          <ul className="legal-tab-ul">
            <li className="legal-tab-li">
              <NavLink
                to="/legal/terms"
                className={({ isActive }) => cn({ active: isActive })}
              >
                Terms of Use
              </NavLink>
            </li>
            <li className="legal-tab-li">
              <NavLink
                to="/legal/prohibited"
                className={({ isActive }) => cn({ active: isActive })}
              >
                Prohibited Uses
              </NavLink>
            </li>
            <li className="legal-tab-li">
              <NavLink
                to="/legal/privacy"
                className={({ isActive }) => cn({ active: isActive })}
              >
                Privacy Policy
              </NavLink>
            </li>
            <li className="legal-tab-li">
              <NavLink
                to="/legal/rental"
                className={({ isActive }) => cn({ active: isActive })}
              >
                Rental Agreement
              </NavLink>
            </li>
            <li className="legal-tab-li">
              <NavLink
                to="/legal/cookie"
                className={({ isActive }) => cn({ active: isActive })}
              >
                Cookies Policy
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      <Outlet />
    </div>
  )
}
